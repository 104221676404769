import { ApiCore } from './utilities/core'
import { SizzleApp } from '../../types/sizzleApp'

const url = 'user'
const plural = 'users'
const single = 'user'

export const apiUser = new ApiCore({
    getAll: true,
    getSingle: true,
    post: true,
    deleteSingle: false,
    put: false,
    url: url,
    plural: plural,
    single: single
})
