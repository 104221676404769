import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import NormalLink from '../header/NormalLink'

const Footer: React.FC = () => {
    const location = useLocation()
    const currentPath = location.pathname == '/' ? '' : location.pathname

    return (
        <div className="w-full bg-primaryText pb-8 md:pb-5 pt-10">
            <div className="flex flex-col md:flex-row pb-5">
                <div className="px-5 flex items-center w-full md:w-1/2">
                    <img src="/images/logo.png" alt="Logo" className="h-16 w-16" />
                    <Link to="/">
                        <h1 className="text-black font-medium text-2xl">AppSizzle</h1>
                    </Link>
                </div>
                <div className="px-5 flex flex-row md:gap-5 w-full md:w-1/2 pb-0">
                    <NormalLink text="CONTACT" destination={currentPath + '/modal/contact'} onClick={() => {}} className="text-black" />
                    <NormalLink text="PRIVACY POLICY" destination="/legal/privacy-policy" onClick={() => {}} className="text-black" />
                    <NormalLink text="TERMS & CONDITIONS" destination="/legal/terms" onClick={() => {}} className="text-black" />
                </div>
            </div>
        </div>
    )
}
export default Footer
