import React from 'react'

interface CTAButtonProps {
    text: string
}
const CTAButton: React.FC<CTAButtonProps> = ({ text }) => {
    return (
        <button className="font-medium rounded pt-2 pb-[4px] px-4 bg-base-200 hover:border-transparent transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0">
            {text}
        </button>
    )
}
export default CTAButton
