import React, { useEffect, useRef, useState } from 'react'

interface VideoPlayerProps {
    url: string
    previewImage: string
    autoplayOnScroll: boolean
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, previewImage, autoplayOnScroll }) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const [showVideo, setShowVideo] = useState(false)
    const ref = useRef(null)

    const handleMouseEnter = async () => {
        if (autoplayOnScroll) {
            return
        }
        if (videoRef.current) {
            try {
                await videoRef.current.play()
            } catch (e) {
                console.error(e)
            }
        }
        setShowVideo(true)
    }
    const handleMouseLeave = () => {
        if (autoplayOnScroll) {
            return
        }
        if (videoRef.current) {
            videoRef.current.currentTime = 0
            videoRef.current.pause()
        }
        setShowVideo(false)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!autoplayOnScroll) {
                    return
                }
                if (videoRef.current == null) {
                    return
                }

                if (showVideo == entry.isIntersecting) {
                    return
                }

                if (!entry.isIntersecting) {
                    videoRef.current.currentTime = 0
                    videoRef.current.load()
                    videoRef.current.pause()
                } else {
                    videoRef.current.currentTime = 0
                    videoRef.current.play()
                }
                setShowVideo(entry.isIntersecting)
            },
            { rootMargin: '-100px' }
        )
        if (ref.current != null) {
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [])

    const showVideoPlayer = showVideo || previewImage == null || previewImage == ''
    return (
        <div className="overflow-hidden rounded-sm" ref={ref} style={{ aspectRatio: 0.5 }} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
            <video
                preload="auto"
                className=""
                muted
                playsInline
                autoPlay
                loop
                ref={videoRef}
                style={{ display: showVideoPlayer ? 'inline' : 'none', width: '100%' }}
            >
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <img className="" src={previewImage} alt="Preview Image" style={{ display: showVideoPlayer ? 'none' : 'inline', width: '100%' }} />
        </div>
    )
}

export default VideoPlayer
