import React from 'react'

interface MiddleFeatureCardProps {
    heading: string
    subText: string
    image: string
}

const MiddleFeatureCard: React.FC<MiddleFeatureCardProps> = ({ heading, subText, image }) => {
    return (
        <div
            className={`overflow-y-auto bg-featureCardBg  flex items-center flex-col rounded-lg text-center space-y-4 py-24 px-6 w-[375px]`}
            style={{ boxShadow: '0px 4px 25px 7px rgba(0, 0, 0, 0.45), 0px 0px 16px 0px #FE8528' }}
        >
            <img className="pb-4 w-[134px]" src={`/images/${image}.png`} alt="Instant Video Generation"></img>
            <h1 className={`text-xl font-semibold `}>{heading} </h1>

            <p className={`text-lg`}> {subText}</p>
        </div>
    )
}
export default MiddleFeatureCard
