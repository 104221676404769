import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import Loading from '../../components/Loading'
import AppStoreQueryResult from '../../types/appstoreQueryItem'
import { debounce } from 'lodash'
import axios from 'axios'

import { AppQueryItem } from '../../types/appQueryItem'
import AppRow from '../../components/table_row/table_row'
import { TitleSetContext } from '../../utility/ModalContext'

interface AppStoreSeatchProps {
    onSelection: (appID: string) => void
}

const AppStoreSearch: React.FC<AppStoreSeatchProps> = ({ onSelection }) => {
    const [appQuery, setAppQuery] = useState<AppStoreQueryResult | null>(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [loadingNew, setLoadingNew] = useState(false)

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        // Focus the input element when the component mounts
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const handleSearch = useMemo(
        () =>
            debounce(async (query: string) => {
                const jobResponse = await axios.get('https://itunes.apple.com/search?media=software&term=' + query)
                setAppQuery(jobResponse.data)
                setLoadingNew(false)
            }, 500), // This will delay the search call by 500ms
        []
    )

    const appQueryItems = appQuery?.results.map((item) => new AppQueryItem(item))

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        setSearchTerm(value)
        handleSearch(value)
        setLoadingNew(true)
    }

    return (
        <div className="w-full relative" onClick={() => {}}>
            <input
                id="autocompleteInput"
                type="text"
                ref={inputRef}
                placeholder="Search..."
                className="text-base caret-brandOrange bg-base-200  focus:ring-1 focus:ring-colorBorder outline-none placeholder-white px-5 py-3 w-full rounded-md"
                onChange={handleInputChange}
            />

            {searchTerm != '' && (
                <div id="dropdown" className="h-60 border border-gray-300 rounded-md bg-base-100 absolute overflow-y-auto">
                    <div className="grid-cols-1">
                        {loadingNew && (
                            <div className="flex">
                                <Loading visible={true} />
                                <p>Loading</p>
                            </div>
                        )}
                        {appQueryItems != undefined &&
                            appQueryItems.map((result) => {
                                return (
                                    <AppRow
                                        id={result.getAppID()}
                                        title={result.trackName}
                                        image={result.artworkUrl512}
                                        clicked={(value) => {
                                            onSelection(value)
                                        }}
                                        key={result.getAppID()}
                                    />
                                )
                            })}
                    </div>
                </div>
            )}
        </div>
    )
}
export default AppStoreSearch
