import React from 'react'
import FormFieldTitleComponent from './FormFieldTitle'

interface DescriptionProps {
    description: string
    onChange: (string) => void
}

const DescriptionComponent: React.FC<DescriptionProps> = ({ description, onChange }) => {
    return (
        <div className="flex-col flex">
            <div className="">
                <FormFieldTitleComponent title="Description" subTitle="Edit your app description below" />
            </div>
            <textarea
                value={description}
                onChange={(e) => onChange(e.target.value)}
                className="w-full h-[129px] lg:h-[204px] relative py-3 px-2 bg-base-100 rounded-xl text-[16px] md:text-lg outline-none focus:ring-1 focus:ring-brandOrange"
            ></textarea>
        </div>
    )
}
export default DescriptionComponent
