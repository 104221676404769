export const ROUTES = {
    HOMEPAGE_ROUTE: {
        name: 'home',
        route: '/'
    },
    VIDEO_ROUTE: {
        name: 'video',
        route: '/video/:id'
    },
    TEMPLATE_ROUTE: '/template/:id',
    TEMPLATE_APP_ROUTE: '/template/:id/:appID',
    CHECKOUT_ROUTE: '/checkout/:jobID',
    SHOW_TEMPLATES_ROUTE: {
        name: 'templates',
        route: '/templates'
    },
    SHOW_TEMPLATES_ROUTE_WITH_APP: {
        name: 'templates',
        route: (id: string) => {
            return '/templates/' + id
        }
    },
    HOTDOG: '/hotdog',
    LEADS: '/leads',
    LEAD: {
        name: 'lead',
        route: (id: string) => {
            return '/lead/' + id
        }
    },
    GET_STARTED: {
        name: 'get_started',
        route: (templateID: string | null) => {
            const templateIDAddOn = templateID != null ? '/' + templateID : ''
            return '/get_started' + templateIDAddOn
        }
    },
    GET_STARTED_APP_IMPORT: {
        route: (templateID: string | null) => {
            const templateIDAddOn = templateID != null ? '/' + templateID : ''
            return '/get_started/app_store_import' + templateIDAddOn
        }
    },

    GET_STARTED_APP_EDIT: {
        route: (appID: string, templateID: string | null) => {
            const appIDAddOn = appID != null ? '/' + appID : ''
            const templateIDAddOn = templateID != null ? '/' + templateID : ''
            return '/get_started/app_edit' + appIDAddOn + templateIDAddOn
        }
    },
    PRIVACY_POLICY: '/legal/privacy-policy',
    TERMS_CONDITIONS: '/legal/terms',
    MY_APPS: '/apps',
    EDIT_APP: {
        name: 'edit_app',
        route: (id: string) => {
            return '/apps/' + id
        }
    },
    VIDEO_CREATE_SELECT_APP: '/video_create/:templateId',
    VIDEO_CREATE_VIEW_PREVIEW: {
        route: (id: string) => {
            return '/video_create/preview/' + id
        },
        purchasedRoute: (id: string) => {
            return '/video_create/purchased/' + id
        }
    },
    MANUALLY_CREATE_APP_NAME: {
        name: 'manually_create_app_name',
        route: (id: string, templateID: string | null) => {
            const addOn = templateID != null ? '/' + templateID : ''
            return '/get_started/manual/name/' + id + addOn
        }
    },
    MANUALLY_CREATE_APP_DESCRIPTION: {
        name: 'manually_create_app_description',
        route: (id: string, templateID: string | null) => {
            const addOn = templateID != null ? '/' + templateID : ''
            return '/get_started/manual/description/' + id + addOn
        }
    },
    MANUALLY_CREATE_APP_ASSET_QUESTION: {
        name: 'manually_create_app_asset_question',
        route: (id: string, templateID: string | null) => {
            const addOn = templateID != null ? '/' + templateID : ''
            return '/get_started/manual/asset_question/' + id + addOn
        }
    },
    MANUALLY_CREATE_APP_ASSETS: {
        name: 'manually_create_app_assets',
        route: (id: string, templateID: string | null) => {
            const addOn = templateID != null ? '/' + templateID : ''
            return '/get_started/manual/assets/' + id + addOn
        }
    },
    DRAFTS: {
        name: 'drafts',
        route: () => {
            return 'draftPage'
        }
    },
    LOGIN: {
        name: 'login',
        route: () => {
            return '/login'
        }
    },
    CHECKOUT: {
        name: 'checkout',
        route: (formId: string) => {
            return '/checkout/' + formId
        }
    },
    CONTACT: {
        name: 'contact',
        route: () => {
            return '/contact'
        }
    }
}
