import React from 'react'

interface FeatureCardProps {
    title: string
    subTitle: string
}

const FormFieldTitleComponent: React.FC<FeatureCardProps> = ({ title, subTitle }) => {
    return (
        <div className="pl-4 lg:pl-[33px] pb-0 lg:pb-[13px] flex flex-col">
            <h1 className="text-xl lg:text-2xl font-medium">{title}</h1>
            <p className="hidden lg:inline text-xs"> {subTitle}</p>
        </div>
    )
}
export default FormFieldTitleComponent
