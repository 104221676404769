import React from 'react'

interface EditVideoButtonProps {
    text: string
    onClick: () => void
}
const EditVideoButton: React.FC<EditVideoButtonProps> = ({ text, onClick }) => {
    return (
        <div className="">
            <button
                onClick={onClick}
                className={`border border-brandOrange font-semibold rounded-xl py-2 px-8 bg-editVideoButtonBg hover:border-transparent transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0`}
            >
                {text}
            </button>
        </div>
    )
}
export default EditVideoButton
