import React from 'react'
import Template from '../../types/templates'

import VideoPlayer from '../videoplayer/VideoPlayer'
import CTAButton from '../buttons/CTAButton'

interface FeatureCardProps {
    heading: string
    subText: string
    main: boolean
    bgColor: string
    image: string
}

const FeatureCard: React.FC<FeatureCardProps> = ({ heading, subText, bgColor, image, main }) => {
    const dynamicBackground = `bg-${bgColor}`

    const subColor = main ? 'text-almostBlack ' : ''

    console.log(dynamicBackground)
    return (
        <div
            className={`overflow-y-auto bg-featureCardBg flex items-center flex-col rounded-lg text-center space-y-4 py-16 px-5 w-[375px]`}
            style={{ boxShadow: '0px 4px 7px 3px rgba(204, 201, 193, 0.40)' }}
        >
            <img className="pb-4 h-[124px]" src={`/images/${image}.png`} alt="Your SVG Image"></img>

            <h1 className={`text-xl font-bold ${subColor}`}>{heading} </h1>

            <p className={`${subColor} text-lg font-base`}> {subText}</p>
        </div>
    )
}
export default FeatureCard
