import React, { useEffect, useRef, useState } from 'react'

import PageLoading from '../../components/loading/PageLoading'
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Modal from '../../components/modal/Modal'

import { FormDataProvider } from '../../components/Form/FormContext'
import VideoEditModalPage from './VideoEditModalPage'
import BuyButton from '../../components/buttons/BuyButton'

import EditVideoButton from '../../components/buttons/EditVideoButton'
import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'
import { ModalContext } from '../../utility/ModalContext'
import FormDraft from '../../types/form'
import { ROUTES } from '../../resources/routes-constants'
import LoadingButton from '../../components/buttons/LoadingButton'
import ThreeDText from '../../components/hero/ThreeDText'

import { sendtoMixpanel } from '../../utility/Mixpanel'
import { useMixpanel } from 'react-mixpanel-browser'

const mobilePreviewFeatures = (isPaid: boolean) => {
    if (isPaid) {
        return null
    }
    return (
        <div>
            <h1 className="text-5xl text-center pb-4"> Perks of Purchase:</h1>
            <div className="flex space-x-4">
                <div className="bg-tertBackground rounded-full text-center py-3 p-4">Full Resolution</div>
                <div className="bg-tertBackground rounded-full text-center py-3 p-4">Watermark Removal</div>
                <div className="bg-tertBackground rounded-full text-center py-3 p-4">Royalty Free</div>
            </div>
        </div>
    )
}

const videoPreviewText = (isPaid: boolean) => {
    return <ThreeDText text={isPaid ? 'Thanks For Your Purchase' : 'Video Preview'} color="text-white" />
}

interface VideoCreatePreviewFormParams {
    formId: string
}

const VideoCreatePreviewForm: React.FC = () => {
    const { formId } = useParams<keyof VideoCreatePreviewFormParams>() as VideoCreatePreviewFormParams
    const [loading, setLoading] = useState<boolean>(false)
    const pollingInterval = useRef<NodeJS.Timer | null>(null) // 2000 milliseconds = 2 seconds
    const [formDraft, setFormDraft] = useState<FormDraft | null>(null)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024)
    const [requestingDownloadURL, setRequestingDownloadURL] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const mixpanel = useMixpanel()

    const reloadState = async () => {
        handlePreviewJobStart()
    }
    useEffect(() => {
        sendtoMixpanel(mixpanel, 'page_load', 'VideoPreview')
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 1024)
        }

        window.addEventListener('resize', handleResize)

        setLoading(true)
        reloadState()
        return () => {
            window.removeEventListener('resize', handleResize)
            if (pollingInterval.current != null) {
                clearInterval(pollingInterval.current)
            }
        }
    }, [])

    useEffect(() => {
        if (location.pathname.includes('purchased')) {
            setLoading(true)
            reloadState()
        }
    }, [location.pathname])

    const handlePreviewJobStart = () => {
        if (pollingInterval.current != null) {
            clearInterval(pollingInterval.current)
        }
        pollingInterval.current = setInterval(() => {
            makePollingRequest()
        }, 1000)
    }
    const makePollingRequest = async () => {
        try {
            const form = await axios.get('/v1/form/' + formId)
            const formData = form.data.formDraft as FormDraft
            setFormDraft(formData)
            const job = formData.paidJob ?? formData.job
            console.log(formData.paidJob)
            if (job.finishedURL != null && job.finishedURL != '') {
                setLoading(false)
                if (pollingInterval.current != null) {
                    clearInterval(pollingInterval.current)
                }
            } else if (job.status == 'failed') {
                if (pollingInterval.current != null) {
                    clearInterval(pollingInterval.current)
                }
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            return
        }
    }

    const paidJob = async () => {
        navigate(location.pathname + '/modal' + ROUTES.CHECKOUT.route(formId))
    }
    const formatCurrency = (amount: number): string => {
        const formattedAmount = (amount / 100).toFixed(2)
        return `$${formattedAmount}`
    }

    if (loading || formDraft == null) {
        return (
            <div className="flex flex-col items-center p-24 min-h-screen">
                <PageLoading visible />
            </div>
        )
    }

    if (formDraft.template === null) {
        return (
            <div>
                <h1> Page not found!</h1>
            </div>
        )
    }

    const isPaid = formDraft.paidJob != null

    const finishedURL = formDraft.paidJob != null ? formDraft.paidJob.finishedURL : formDraft.job.finishedURL

    if (finishedURL != null) {
        return (
            <div className="relative">
                <div className="absolute overflow-hidden w-sreen h-screen opacity-30 -z-8">
                    <div
                        className="top-20 left-20"
                        style={{
                            width: 459.9,
                            height: 475.92,
                            transform: 'rotate(-0.92deg)',
                            transformOrigin: '0 0',
                            background: 'rgba(254, 133, 40, 1.0)',
                            boxShadow: '400px 400px 400px ',
                            borderRadius: 9999,
                            filter: 'blur(400px)'
                        }}
                    />
                    <img
                        src="/images/bgtexture.png"
                        alt="Background Pattern"
                        style={{ userSelect: 'none' }}
                        className="w-[2303px] h-[1449px] -left-[253px] top-0 object-cover"
                    />
                </div>

                <div className={'relative flex z-1 flex-col space-y-8 items-center lg:justify-center lg:space-y-0 ' + (isPaid ? '' : 'lg:flex-row')}>
                    <div className="flex flex-col space-y-8 items-center">
                        {videoPreviewText(isPaid)}
                        <div className="flex-col flex space-x-4">
                            {!isPaid && (
                                <div className="flex space-x-8">
                                    <div>
                                        <BuyButton bgColor="brandOrange" onClick={paidJob} text={'PURCHASE ' + formatCurrency(formDraft.template.price)} />
                                    </div>

                                    <div>
                                        <EditVideoButton
                                            onClick={() => {
                                                setShowModal(true)
                                            }}
                                            text="EDIT"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {isLargeScreen && mobilePreviewFeatures(isPaid)}
                    </div>

                    <div className="flex flex-col items-center space-y-8 pt-2">
                        <div className="w-44 md:w-52 lg:w-64">
                            <video muted autoPlay controls className="" key={finishedURL}>
                                <source src={finishedURL} type="video/mp4" />
                                {/* Add additional <source> elements for different video formats (e.g., WebM, Ogg) */}
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className="opacity-100 lg:opacity-0 pb-6">{mobilePreviewFeatures(isPaid)}</div>
                    </div>
                    {isPaid && (
                        <div className="pb-10">
                            <LoadingButton
                                text="Download Video"
                                onClick={async () => {
                                    setRequestingDownloadURL(true)
                                    const response = await axios.post('/v1/checkout/VideoURL', {
                                        url: finishedURL
                                    })
                                    setRequestingDownloadURL(false)
                                    window.location.href = response.data.url
                                }}
                                isLoading={requestingDownloadURL}
                            />
                        </div>
                    )}
                </div>

                <ModalContext>
                    <Modal
                        title="Edit Video"
                        size={{ height: '90vh', width: '90%' }}
                        isOpen={showModal}
                        onRequestClose={function (): void {
                            setShowModal(false)
                        }}
                        sizeInfo="h-screen w-full md:h-80v md:w-9/12"
                    >
                        <ModalCompatibleTitle title="Edit Video" />
                        <FormDataProvider layers={formDraft.template.layers} templateID={formDraft.template.id} key={formId} defaultValues={formDraft.formInfo}>
                            <VideoEditModalPage
                                template={formDraft.template}
                                didClickSaveWithInfo={async (info) => {
                                    const form = await axios.post('/v1/form/' + formId, {
                                        formInfo: info
                                    })
                                    setShowModal(false)
                                    reloadState()
                                }}
                            />
                        </FormDataProvider>
                    </Modal>
                </ModalContext>
            </div>
        )
    }

    const percentComplete = formDraft.paidJob?.percentComplete ?? formDraft.job.percentComplete

    return (
        <div className="self-center p-10 md:p-20 min-h-screen">
            <h1 className="py-5 text-center text-4xl">
                {' '}
                {isPaid
                    ? "Thank you for your purchase. Your video will be ready shortly. We will also email you when it's done."
                    : 'Rendering your video preview. This can take up to 30 seconds'}
            </h1>
            <div className="w-full bg-colorBorder rounded-full h-2.5">
                <div className="bg-brandOrange h-2.5 rounded-full transition-all duration-1000 ease-in-out" style={{ width: percentComplete + '%' }}></div>
            </div>
        </div>
    )
}

export default VideoCreatePreviewForm
