import { ApiCore } from './utilities/core'
import Lead from '../../types/lead'

const url = 'lead'
const plural = 'leads'
const single = 'lead'

// plural and single may be used for message logic if needed in the ApiCore class.

export const apiLead = new ApiCore({
    getAll: true,
    getSingle: true,
    post: true,
    deleteSingle: true,
    put: true,
    url: url,
    plural: plural,
    single: single
})

export interface GetLeadsResponse {
    leads: Lead[]
}
