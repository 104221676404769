import React, { useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'

import StartButton from '../buttons/StartButton'
import HeroVideos from './heroVideo'
import ThreeDText from './ThreeDText'

const Hero: React.FC = () => {
    const navigate = useNavigate()

    return (
        <div>
            <div className="flex flex-col items-center lg:h-screen w-full lg:flex-row space-x-64 2xl:space-x-96 pl-4 pt-8">
                <div className=" lg:text-left p-4 lg:p-8 lg:w-1/2">
                    <div className="flex-col flex text-center">
                        <h1 className="text-hero-title-sm lg:text-hero-title mb-4 lg:mb-0 tracking-tight leading-none font-bold lg:text-start">
                            App Store
                            <span className="inline lg:hidden"> Videos</span>
                        </h1>

                        <div className="lg:flex space-x-4">
                            <h1 className="hidden lg:inline text-hero-title-sm lg:text-hero-title mb-4 lg:mb-0 tracking-tight leading-none font-bold lg:text-start">
                                Videos
                            </h1>

                            <ThreeDText text="Instantly" color="text-white" />
                        </div>
                        <div className="text-center lg:text-left pt-8">
                            <div className="flex flex-col space-y-2">
                                <p className="md:hidden font-normal text-base md:text-xl lg:text-start lg:text-xl">
                                    {'Create high-quality app store preview videos in minutes. Get your video today for only '}
                                    <span className="text-brandOrange">$0.99!</span>
                                </p>

                                <div>
                                    <p className="hidden md:inline md:text-xl lg:text-start lg:text-lg xl:text-xl 2xl:text-2xl">
                                        {
                                            "Create high-quality app store preview videos in minutes and make a lasting impression on potential users. With our intuitive tool, showcase your app's unique features, boost downloads, and take your marketing to the next level."
                                        }
                                    </p>
                                </div>
                                <div>
                                    <p className="hidden md:inline md:text-xl lg:text-start lg:text-lg xl:text-xl 2xl:text-2xl">
                                        {'Get your video today for only'}
                                    </p>
                                    <p className="text-brandOrange hidden md:inline md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl"> $0.99!</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex-col lg:flex lg:flex-row lg:pt-8 items-center space-x-8">
                            <StartButton
                                text="Create Your Video"
                                onClick={() => {
                                    navigate(ROUTES.SHOW_TEMPLATES_ROUTE.route)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <HeroVideos />
            </div>
            <div className="w-full" style={{ background: 'linear-gradient(180deg, rgba(16, 16, 16, 0.00) 0%, #CCC9C1 22%)', height: '93px' }} />
        </div>
    )
}
export default Hero
