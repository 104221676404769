import React, { ChangeEvent, FormEvent, useState } from 'react'
import { PaymentElement } from '@stripe/react-stripe-js'
import axios from 'axios'
import { useStripe, useElements, LinkAuthenticationElement } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'

import LoadingButton from '../buttons/LoadingButton'
import Loading from '../Loading'
import { ROUTES } from '../../resources/routes-constants'
interface CheckoutFormProps {
    formId: string
    initialPrice: number
}

const formatCurrency = (amount: number): string => {
    const formattedAmount = (amount / 100).toFixed(2)
    return `$${formattedAmount}`
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ formId, initialPrice }) => {
    const stripe = useStripe()
    const elements = useElements()
    const navigate = useNavigate()
    const [isLoading, setisLoading] = useState(false)
    const [showDiscountCode, setShowDiscountCode] = useState<boolean>(false)
    const [discountCode, setDiscountCode] = useState<string>('')
    const [stripeComplete, setStripeComplete] = useState<boolean>(false)
    const [emailComplete, setEmailComplete] = useState<boolean>(false)
    const [emailValue, setEmailValue] = useState<string>('')
    const [price, setPrice] = useState<number>(initialPrice)
    const [discountMessage, setDiscountMessage] = useState<string>('')
    const [failureMessage, setFailureMessage] = useState<string>('')

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        e.preventDefault()
        handlePayment()
    }
    const handlePayment = async () => {
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setisLoading(true)

        const discountResponse = await axios.post('/v1/checkout/discount/apply', {
            formId: formId,
            discountCode: discountCode,
            email: emailValue
        })
        if (discountResponse.status == 200) {
            if (discountResponse.data.Valid == true) {
                if (discountResponse.data.price == 0) {
                    navigate(ROUTES.VIDEO_CREATE_VIEW_PREVIEW.purchasedRoute(formId))
                    return
                } else if (discountResponse.data.price > price) {
                    setisLoading(false)
                    setPrice(discountResponse.data.price)
                    setFailureMessage('An error occurred.')
                    return
                }
            }
        } else {
            setisLoading(false)
            setFailureMessage('An error occurred.')
            return
        }

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: ''
            },
            redirect: 'if_required'
        })

        const response = await axios.post('/v1/checkout/confirm', {
            formId: formId,
            email: emailValue
        })

        console.log(response.status)

        if (response.data.success == true) {
            navigate(ROUTES.VIDEO_CREATE_VIEW_PREVIEW.purchasedRoute(formId))
        } else {
            // TODO: Handle errors
            console.log('Failure')
        }
        setisLoading(false)
    }

    if (!stripe || !elements) {
        return <Loading />
    }

    return (
        <div className="flex justify-center items-center py-5">
            <form className="w-3/4 self-center" onSubmit={handleSubmit}>
                <div className="">
                    <LinkAuthenticationElement
                        onChange={(e) => {
                            setEmailValue(e.value.email)
                            setEmailComplete(e.complete)
                        }}
                    />
                    <div className="">
                        {price != 0 && (
                            <PaymentElement
                                onChange={(e) => {
                                    setStripeComplete(e.complete)
                                }}
                            />
                        )}
                        {!showDiscountCode && (
                            <div className="flex flex-col">
                                <h1
                                    className="text-sm text-right pt-4 text-brandOrange cursor-pointer"
                                    onClick={() => {
                                        setShowDiscountCode(true)
                                    }}
                                >
                                    I have a discount code
                                </h1>
                            </div>
                        )}
                        {showDiscountCode && (
                            <div>
                                <h1 className="py-2 pt-4">Discount Code</h1>
                                <div className="flex flex-row rounded-xl ">
                                    <button
                                        onClick={async () => {
                                            const response = await axios.post('/v1/checkout/discount/verify', {
                                                formId: formId,
                                                discountCode: discountCode
                                            })
                                            if (response.status == 200) {
                                                if (response.data.Valid == true) {
                                                    setPrice(response.data.price)
                                                } else {
                                                    setPrice(initialPrice)
                                                    setDiscountCode('')
                                                }
                                                setDiscountMessage(response.data.message)
                                            }
                                        }}
                                        type="button"
                                        className="select rounded-sm lg:h-[56px] h-[48px] border-colorBorder border hover:cursor-pointer rounded-l-xl"
                                    >
                                        <p className="px-4">Apply</p>
                                    </button>
                                    <input
                                        type="text"
                                        name="Discount"
                                        className="lg:min-h-[56px] w-full min-h-[33px] py-3 px-2 bg-textInputBg text-[16px] outline-none focus:ring-1 focus:ring-brandOrange rounded-r-xl"
                                        placeholder="Optional"
                                        value={discountCode}
                                        onChange={(e) => {
                                            setDiscountCode(e.target.value)
                                        }}
                                        required
                                    />
                                </div>
                                {discountMessage != '' && <p>{discountMessage}</p>}
                            </div>
                        )}
                        {failureMessage != '' && <p>failureMessage</p>}
                        <div className="py-5 ">
                            <LoadingButton
                                isLoading={isLoading}
                                text={price == 0 ? 'Create Video Free' : 'Submit Payment For ' + formatCurrency(price)}
                                enabled={(stripeComplete || price == 0) && emailComplete}
                                onClick={async () => {
                                    handlePayment()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CheckoutForm
