import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import NormalLink from './NormalLink'
import { Fade as Hamburger } from 'hamburger-react'
import ScreenSizeIndicator from '../ScreenSizeIndicator'
import { ROUTES } from '../../resources/routes-constants'

const NewHeader: React.FC = () => {
    const isLocal = process.env.REACT_APP_RUNNING_LOCAL ? true : false
    const [isOpen, setIsOpen] = useState(false)
    const location = useLocation()

    useEffect(() => {
        setIsOpen(false)
    }, [location])

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }
    console.log('Location is:')
    console.log(location.pathname)
    const appCount = +(localStorage.getItem('last_known_app_count') ?? '0')

    const currentPath = location.pathname == '/' ? '' : location.pathname

    return (
        <nav className="fixed w-full z-20 top-0 left-0 border-gray-600">
            <div className="text-textPrimary w-full bg-cover bg-center relative" style={{ backgroundImage: 'url("/images/headerbg.svg")', minHeight: '100px' }}>
                <div className="flex px-8 py-4 md:px-4 xl:px-24 justify-between ">
                    {isLocal && <ScreenSizeIndicator className="absolute top-0 left-0 bg-white px-2 py-1 text-xs" />}

                    <div className="logo-container flex items-center justify-center">
                        <img src="/images/logo.png" alt="Logo" className="h-16 w-16" />
                        <Link to="/" className="text-3xl font-semibold">
                            AppSizzle
                        </Link>
                    </div>

                    <div className="flex space-x-6 items-center">
                        <NormalLink text="TEMPLATES" destination="/templates" className="text-xl hidden lg:inline" onClick={() => {}} />
                        {appCount > 0 && <NormalLink text="MY APPS" destination="/apps" className="text-xl hidden lg:inline" onClick={() => {}} />}
                        {isLocal && <NormalLink text="DRAFTS" destination={ROUTES.DRAFTS.route()} className="text-xl hidden lg:inline" onClick={() => {}} />}

                        {localStorage.getItem('account_type') == null ||
                            (isLocal && localStorage.getItem('account_type') == 'anonymous_user' && (
                                <NormalLink text="LOGIN" destination={currentPath + '/modal/login'} className="text-xl hidden lg:inline" onClick={() => {}} />
                            ))}

                        {isLocal && localStorage.getItem('account_type') != null && localStorage.getItem('account_type') !== 'anonymous_user' && (
                            <NormalLink
                                text="LOG OUT"
                                destination={undefined}
                                className="text-xl hidden lg:inline text-red"
                                onClick={() => {
                                    localStorage.clear()
                                    window.location.replace('/')
                                }}
                            />
                        )}

                        {/* <Link to="/" className="hidden lg:inline">
                            <SignUpButton />
                        </Link> */}
                        <div className="z-10 lg:hidden">
                            <Hamburger toggled={isOpen} toggle={toggleMenu} />
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <div className="fixed inset-0  lg:hidden bg-black opacity-25" onClick={toggleMenu}></div>}
            {isOpen && (
                <div className="absolute  lg:hidden top-0 right-0 mt-4 py-2 rounded shadow-lg w-5/6 md:w-1/2 h-screen bg-base-100">
                    {/* Menu items */}
                    <div className="px-5 pt-10 flex flex-col gap-5">
                        <NormalLink text="HOME" destination="/" onClick={() => {}} />
                        {appCount > 0 && <NormalLink text="MY APPS" destination="/apps" onClick={() => {}} />}
                        {isLocal && <NormalLink text="DRAFTS" destination={ROUTES.DRAFTS.route()} onClick={() => {}} />}
                        <NormalLink text="TEMPLATES" destination="/templates" onClick={() => {}} />
                        {isLocal && (
                            <div>
                                {localStorage.getItem('account_type') == null || localStorage.getItem('account_type') == 'anonymous_user' ? (
                                    <NormalLink text="LOGIN" destination={currentPath + '/modal/login'} onClick={() => {}} />
                                ) : (
                                    <NormalLink
                                        text="LOG OUT"
                                        destination={undefined}
                                        onClick={() => {
                                            localStorage.clear()
                                            window.location.replace('/')
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    {/* Add more menu items as needed */}
                </div>
            )}
        </nav>
    )
}
export default NewHeader
