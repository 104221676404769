import Cookies from 'js-cookie'

export function sendtoMixpanel(mixpanel: any, action: string, page: string) {
    const mixPanelProps = {}

    const cookieConsent = Cookies.get('cookieConsent')

    if (cookieConsent === null || cookieConsent === undefined || cookieConsent === 'false') {
        console.log('DONT SEND DATA!!!!!')
        return
    }
    const user_id = localStorage.getItem('user_id')

    if (user_id !== null) {
        mixPanelProps['user_id'] = user_id
    }

    if (action !== '') {
        mixPanelProps['action'] = action
    }

    if (page !== '') {
        mixPanelProps['page'] = page
    }
    mixPanelProps['hostname'] = window.location.hostname

    const trackId = page + '_' + action

    mixpanel.track(trackId, {
        mixPanelProps
    })
}
