import React from 'react'
import Loading from '../Loading'

interface LoadingButtonProps {
    text: string
    isLoading: boolean
    enabled?: boolean
    onClick: () => void
    type?: 'button' | 'submit'
}
const LoadingButton: React.FC<LoadingButtonProps> = ({ text, isLoading, enabled = true, type = 'button', onClick }) => {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={!enabled || isLoading}
            className={
                'w-full h-fit text-black  hover:cursor-pointer focus:outline-none font-medium rounded-xl text-lg px-5 py-2.5 text-center mr-2 mb-2 ' +
                (enabled ? 'bg-brandOrange' : 'bg-disabledState')
            }
        >
            <div className="flex flex-auto justify-center text-center">
                <div className={isLoading ? '' : 'w-0'}>
                    <Loading visible={isLoading} />
                </div>
                <span className="self-center">{isLoading ? '' : text}</span>
            </div>
        </button>
    )
}
export default LoadingButton
