import React from 'react'

interface DeleteButtonProps {
    text: string
    onClick: () => void
}
const DeleteButton: React.FC<DeleteButtonProps> = ({ text, onClick }) => {
    return (
        <div className="">
            <button
                onClick={onClick}
                className="font-medium rounded pt-2 pb-[4px] px-4 bg-red-500  hover:border-transparent transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0"
            >
                {text}
            </button>
        </div>
    )
}
export default DeleteButton
