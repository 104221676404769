import React, { useContext, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'

import AppStoreSearch from './AppStoreSearch'
import axios from 'axios'
import AppInfo from '../../types/appInfo'
import { AppDataProvider } from '../../utility/AppContext'

import { sendtoMixpanel } from '../../utility/Mixpanel'

import Loading from '../../components/Loading'
import { ROUTES } from '../../resources/routes-constants'
import { TitleSetContext } from '../../utility/ModalContext'
import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'

interface AppStoreImporterParams {
    templateId: string | null
}

const AppStoreImporterPage: React.FC = () => {
    const { templateId } = useParams<keyof AppStoreImporterParams>() as AppStoreImporterParams
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useLocation().state.modalFriendlyNavigate
    const mixpanel = useMixpanel()
    const location = useLocation()
    const appContext = useContext(AppDataProvider)
    const setTitleContext = useContext(TitleSetContext)
    const retrieveAppInfo = async (appID: string) => {
        setLoading(true)
        const jobResponse = await axios.post('/v1/createApp?url=' + 'https://apps.apple.com/us/app/' + appID)
        // Clear app context cache
        await appContext(true)
        const appInfo = jobResponse.data as AppInfo
        sendtoMixpanel(mixpanel, 'app_imported', 'AppStoreImporter')
        navigate(ROUTES.GET_STARTED_APP_EDIT.route(appInfo.dbApp.id, templateId))
    }

    return !loading ? (
        <div className="min-h-full bg-">
            <div className="flex flex-col justify-center bg-base-100 border-colorBorder border m-5 p-5 rounded-sm gap-10">
                <div className="flex flex-col gap-4">
                    <ModalCompatibleTitle title={'Find your app'} />
                    <p className="text-center">{"Start typing your app name, we'll import everything you need to make your video."}</p>
                    <AppStoreSearch onSelection={retrieveAppInfo} />
                </div>
                {/* <h1 className="text-center">Or</h1> */}
                <div className="flex flex-col justify-center w-1/2 self-center">
                    {/* <button
                        type="button"
                        onClick={async () => {
                            const app = await axios.post('/v1/app/manual/start')
                            navigate(ROUTES.MANUALLY_CREATE_APP_NAME.route(app.data.id, templateId))
                        }}
                        className="h-fit text-base-100 bg-brandOrange hover:cursor-pointer focus:outline-none font-medium rounded-sm text-lg px-5 py-2 text-center mb-2"
                    >
                        <div className="flex flex-auto justify-center text-center">
                            <span className="self-center">{'Import Your App Manually'}</span>
                        </div>
                    </button> */}
                </div>
            </div>
        </div>
    ) : (
        <div className="flex flex-col items-center justify-center p-4">
            <h1>Importing your app, this should only take a few seconds!</h1>
            <Loading visible={true} />
        </div>
    )
}

export default AppStoreImporterPage
