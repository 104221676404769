import React, { useState, ChangeEvent, useRef, FormEvent, useContext } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import UploadItem from './UploadItem'
import Loading from '../Loading'
import FileUploadField from './FilUploadField'

interface SortableItemProps {
    item: UploadItem
    filesSelected: (file: FileList, uploadItem: UploadItem) => void
    onDelete: () => void
}

const SortableItemComponent: React.FC<SortableItemProps> = ({ item, filesSelected, onDelete }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    const pressed = attributes['aria-pressed'] ?? false
    return (
        <div style={{ ...style, background: pressed ? 'gray' : 'white', zIndex: pressed ? 999 : 50 }}>
            <div className="relative">
                <div ref={setNodeRef} {...attributes} {...listeners} className="" style={{ touchAction: 'none' }}>
                    {item.s3URL && <img src={item.s3URL} alt="Selected Preview" style={{ height: '100px' }} />}
                    {item.isUploading == true && <Loading visible={true} />}
                    {item.isUploading == false && item.s3URL == null && <FileUploadField uploadItem={item} filesSelected={filesSelected} onDelete={() => {}} />}
                </div>
                {item.s3URL && (
                    <div className="">
                        <button
                            onClick={onDelete}
                            className="absolute -top-1 -right-1 flex items-center justify-center bg-brandOrange rounded-full hover:bg-orange-600 transition duration-300"
                            style={{ fontSize: '14px', width: '20px', height: '20px' }}
                        >
                            X
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SortableItemComponent
