import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

interface NormalLinkProps {
    className?: string
    text: string
    destination?: string
    onClick: () => void
}

const NormalLink: React.FC<NormalLinkProps> = ({ className = '', text, destination, onClick }) => {
    const navigate = useNavigate()
    return (
        <button
            className={'ease-in-out group block py-2 pl-3 pr-4  transition-all duration-500 md:p-0' + ' ' + className}
            onClick={() => {
                if (destination != null) {
                    navigate(destination)
                }
                onClick()
            }}
        >
            <span className="font-medium bg-left-bottom bg-gradient-to-r from-brandOrange to-brandOrange bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out">
                {text}
            </span>
        </button>
    )
}
export default NormalLink
