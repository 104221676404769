import React from 'react'

interface UpdateButtonProps {
    text: string
    onClick: () => void
}
const UpdateButton: React.FC<UpdateButtonProps> = ({ text, onClick }) => {
    return (
        <div className="">
            <button
                onClick={onClick}
                className="text-slate-900 font-medium rounded pt-2 pb-[4px] px-4 bg-green-400  hover:border-transparent transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0"
            >
                {text}
            </button>
        </div>
    )
}
export default UpdateButton
