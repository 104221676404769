import React from 'react'
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'
import HomePage from './pages/HomePage'

import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'

import NewHeader from './components/header/NewHeader'
import Checkout from './pages/Checkout'
import ShowTemplatesPage from './pages/TemplatesPage'
import Playground from './pages/Playground'
import WhatStagePage from './pages/GettingStartedFlow/WhatStage'
import AppStoreImporterPage from './pages/GettingStartedFlow/AppStoreImporterPage'
import PrivacyPolicyPage from './pages/PrivacyPolicy'
import TermsConditionsPage from './pages/TermsAndConditions'
import MyAppsPage from './pages/MyApps'
import EditIOSAppPage from './pages/EditIOSAppPage'
import VideoCreateSelectApp from './pages/VideoCreateFlow/VideoCreateSelectApp'
import VideoCreatePreviewForm from './pages/VideoCreateFlow/VideoCreatePreviewForm'
import ManuallyCreateAppName from './pages/GettingStartedFlow/ManuallyCreate/ManualCreateAppNamePage'
import ManuallyCreateAppDescription from './pages/GettingStartedFlow/ManuallyCreate/ManualCreateAppDescriptionPage'
import ManuallyCreateAppAssetQuestion from './pages/GettingStartedFlow/ManuallyCreate/ManualCreateAppAssetQuestion'
import ManualCreateAppAssetsPage from './pages/GettingStartedFlow/ManuallyCreate/ManualCreateAppAssetsPage'
import GettingStartedEditIOSAppPage from './pages/GettingStartedFlow/GettingStartedEditAppFlow'
import { useLocation } from 'react-router-dom'
import Modal from './components/modal/Modal'
import { ModalContext } from './utility/ModalContext'
import DraftPage from './pages/DraftPage'
import LeadsPage from './pages/Leads/LeadsPage'
import LeadPage from './pages/Leads/LeadPage'
import LoginPage from './pages/Login/Login'
import Footer from './components/footer/Footer'
import ContactPage from './pages/Contact/Contact'

const AppSizzleRoutes = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const modalNavigate = (routeName) => {
        const splitRoutes = path.split('/modal/')
        if (splitRoutes.length > 1) {
            navigate(splitRoutes[0] + '/modal' + routeName, {
                state: {
                    previousRouteIsModal: true
                }
            })
            return
        }

        navigate(routeName)
        return
    }

    const path = location.pathname
    const splitRoutes = path.split('/modal/')
    const isModal = splitRoutes.length > 1
    const pathBeforeModal = splitRoutes[0]
    const pathAferModal = isModal ? '/' + splitRoutes[1] : '/'

    let allState = { ...location.state }

    allState.modalFriendlyNavigate = modalNavigate

    const underLocation = {
        pathname: pathBeforeModal,
        state: allState,
        hash: location.hash,
        key: location.key
    }

    let overlocationProps = { ...allState }
    overlocationProps.inModal = true
    const overLocation = {
        pathname: pathAferModal,
        state: overlocationProps,
        hash: location.hash,
        key: location.key
    }

    return (
        <div>
            <div className="">
                <NewHeader />
            </div>
            <div className="pt-24">
                {RoutesDef(underLocation)}
                <div>
                    <ModalContext>
                        <Modal
                            isOpen={isModal}
                            title={overLocation.props?.title ?? ''}
                            onRequestClose={() => {
                                console.log('OutsideClick')
                                navigate(pathBeforeModal, {
                                    state: {
                                        closedModal: true
                                    }
                                })
                            }}
                            size={{ height: '80vh', width: '80%' }}
                            sizeInfo="h-screen w-full md:h-80v md:w-9/12"
                        >
                            {RoutesDef(overLocation)}
                        </Modal>
                    </ModalContext>
                </div>
                {!location.pathname.startsWith('/apps/') && (
                    <div className="z-1">
                        <Footer />
                    </div>
                )}
            </div>
        </div>
    )
}

const RoutesDef = (location) => {
    const isLocal = process.env.REACT_APP_RUNNING_LOCAL ? true : false

    return (
        <Routes location={location}>
            <Route path="*" element={<NotFoundPage />} />
            <Route path={ROUTES.HOMEPAGE_ROUTE.route} element={<HomePage />} />
            {/* <Route path={ROUTES.VIDEO_ROUTE.route} Component={VideoPage} /> */}
            <Route path={ROUTES.CHECKOUT_ROUTE} Component={Checkout} />
            <Route path={ROUTES.SHOW_TEMPLATES_ROUTE.route} Component={ShowTemplatesPage} />
            <Route path={ROUTES.SHOW_TEMPLATES_ROUTE_WITH_APP.route(':id')} Component={ShowTemplatesPage} />
            <Route path={ROUTES.HOTDOG} Component={Playground} />

            {isLocal && <Route path={ROUTES.LEADS} Component={LeadsPage} />}
            {isLocal && <Route path={ROUTES.LEAD.route(':leadId')} Component={LeadPage} />}

            {[ROUTES.GET_STARTED.route(null), ROUTES.GET_STARTED.route(':templateId')].map((path, index) => {
                return <Route path={path} Component={WhatStagePage} key={index} />
            })}
            {[ROUTES.GET_STARTED_APP_IMPORT.route(null), ROUTES.GET_STARTED_APP_IMPORT.route(':templateId')].map((path, index) => {
                return <Route path={path} Component={AppStoreImporterPage} key={index} />
            })}

            {[ROUTES.GET_STARTED_APP_EDIT.route(':appId', null), ROUTES.GET_STARTED_APP_EDIT.route(':appId', ':templateId')].map((path, index) => {
                return <Route path={path} Component={GettingStartedEditIOSAppPage} key={index} />
            })}

            {/* <Route path={ROUTES.GET_STARTED.route()} Component={WhatStagePage} /> */}
            <Route path={ROUTES.PRIVACY_POLICY} Component={PrivacyPolicyPage} />
            <Route path={ROUTES.TERMS_CONDITIONS} Component={TermsConditionsPage} />
            <Route path={ROUTES.MY_APPS} Component={MyAppsPage} />
            <Route path={ROUTES.EDIT_APP.route(':appId')} Component={EditIOSAppPage} />

            {
                // Video Create
            }
            <Route path={ROUTES.VIDEO_CREATE_SELECT_APP} Component={VideoCreateSelectApp} />
            {[ROUTES.VIDEO_CREATE_VIEW_PREVIEW.route(':formId'), ROUTES.VIDEO_CREATE_VIEW_PREVIEW.purchasedRoute(':formId')].map((path, index) => {
                return <Route path={path} key={index} Component={VideoCreatePreviewForm} />
            })}
            {[ROUTES.MANUALLY_CREATE_APP_NAME.route(':appId', null), ROUTES.MANUALLY_CREATE_APP_NAME.route(':appId', ':templateId')].map((path, index) => {
                return <Route path={path} key={index} Component={ManuallyCreateAppName} />
            })}
            {[ROUTES.MANUALLY_CREATE_APP_DESCRIPTION.route(':appId', null), ROUTES.MANUALLY_CREATE_APP_DESCRIPTION.route(':appId', ':templateId')].map(
                (path, index) => {
                    return <Route path={path} key={index} Component={ManuallyCreateAppDescription} />
                }
            )}
            {[ROUTES.MANUALLY_CREATE_APP_ASSET_QUESTION.route(':appId', null), ROUTES.MANUALLY_CREATE_APP_ASSET_QUESTION.route(':appId', ':templateId')].map(
                (path, index) => {
                    return <Route path={path} key={index} Component={ManuallyCreateAppAssetQuestion} />
                }
            )}
            {[ROUTES.MANUALLY_CREATE_APP_ASSETS.route(':appId', null), ROUTES.MANUALLY_CREATE_APP_ASSETS.route(':appId', ':templateId')].map((path, index) => {
                return <Route path={path} key={index} Component={ManualCreateAppAssetsPage} />
            })}
            <Route path={ROUTES.CHECKOUT.route(':formId')} Component={Checkout} />
            <Route path={ROUTES.DRAFTS.route()} Component={DraftPage} />
            <Route path={ROUTES.LOGIN.route()} Component={LoginPage} />
            <Route path={ROUTES.CONTACT.route()} Component={ContactPage} />
            <Route path={ROUTES.CONTACT.route()} Component={ContactPage} />
        </Routes>
    )
}

export default AppSizzleRoutes
