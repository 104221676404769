import React from 'react'

interface AppRowProps {
    id: string
    title: string
    image: string
    clicked: (id: string) => void
}

const AppRow: React.FC<AppRowProps> = ({ id, title, image, clicked }) => {
    return (
        <div
            className="hover:bg-base-200 cursor-pointer text-gray-300 font-medium flex items-center component-container bg-base-100 transition duration-300 ease-in-out select-none"
            onClick={() => {
                clicked(id)
            }}
        >
            <img src={image} width="50px" className="rounded-sm p-1" />
            <h2 className="break-normal">{title}</h2>
        </div>
    )
}
export default AppRow
