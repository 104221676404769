// core.js

import { apiProvider } from './provider'

export class ApiCore {
    getAll!: (headers?: object) => Promise<any>
    getSingle!: (id: string, headers?: object) => Promise<any>
    post!: (model: any, headers: object) => Promise<any>
    put!: (model: any) => Promise<any>
    patch!: (model: any) => Promise<any>
    deleteSingle!: (id: string) => Promise<any>

    constructor(options: any) {
        if (options.getAll) {
            this.getAll = (headers?) => {
                return apiProvider.getAll(options.plural, headers)
            }
        }

        if (options.getSingle) {
            this.getSingle = (id, headers) => {
                return apiProvider.getSingle(options.url, id, headers)
            }
        }

        if (options.post) {
            this.post = (model, headers) => {
                return apiProvider.post(options.url, model, headers)
            }
        }

        if (options.put) {
            this.put = (model) => {
                return apiProvider.put(options.url, model)
            }
        }

        if (options.deleteSingle) {
            this.deleteSingle = (id) => {
                return apiProvider.deleteSingle(options.url, id)
            }
        }
    }
}
