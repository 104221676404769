import React, { useState } from 'react'
import LoadingButton from '../../components/buttons/LoadingButton'
import axios from 'axios'

import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'

const ContactPage: React.FC = () => {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [apiMessage, setApiMessage] = useState('')

    const sendEmail = async () => {
        const result = await axios.post('/v1/contact', {
            email: email,
            message: message
        })
        if (result.status === 200) {
            setIsLoading(false)
            console.log(result.data)
            setApiMessage('We have received your email and will get in touch with you shortly.')
        }
    }
    if (apiMessage !== '') {
        return (
            <div className="text-center p-4">
                <p>{apiMessage}</p>
            </div>
        )
    }
    function isValidEmail(email: string): boolean {
        // Regular expression for a basic email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        return emailRegex.test(email)
    }
    return (
        <div className="">
            <ModalCompatibleTitle title={'Contact Us'} />
            <form
                onSubmit={async (e) => {
                    e.preventDefault() // Prevent the default form submission
                    setIsLoading(true)
                    sendEmail()
                }}
            >
                <div className="flex flex-col gap-5 p-5">
                    <input
                        type="text"
                        placeholder="your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} // Update the state on user input
                        className="w-full relative py-3 px-2 bg-base-100 rounded-xl text-sm md:text-lg outline-none focus:ring-1 focus:ring-brandOrange"
                    />
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="your message"
                        className="w-full h-[129px] lg:h-[204px] relative py-3 px-2 bg-base-100 rounded-xl text-[16px] md:text-lg outline-none focus:ring-1 focus:ring-brandOrange"
                    ></textarea>

                    <LoadingButton type="submit" isLoading={isLoading} text={'Submit'} enabled={message.length > 0 && isValidEmail(email)} onClick={() => {}} />
                    <p>{apiMessage}</p>
                </div>
            </form>
        </div>
    )
}

export default ContactPage
