import React, { useState, ChangeEvent, useRef, useContext } from 'react'

interface SimpleUploadComponentProps {
    filesSelected: (files: FileList | null) => void
}

const SimpleUploadComponent: React.FC<SimpleUploadComponentProps> = ({ filesSelected }) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null)

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target
        filesSelected(files)
    }
    return (
        <div className="w-full">
            <div className="flex p-4 space-x-4 bg-base-200 border border-dashed border-colorBorder rounded-sm">
                <input id="file_input" type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={hiddenFileInput} multiple />
                <button
                    className="text-brandOrange"
                    onClick={() => {
                        if (hiddenFileInput.current != null) {
                            hiddenFileInput.current.click()
                        }
                    }}
                >
                    Upload
                </button>
            </div>
        </div>
    )
}

export default SimpleUploadComponent
