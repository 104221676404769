let GLOBAL_BASE_URL = 'https://api.appsizzle.io'

const DEV_BASE_URL = 'https://dev.api.appsizzle.io'

const USE_LOCAL = process.env.REACT_APP_USE_LOCAL

const USE_DEV = process.env.REACT_APP_USE_DEV

if (USE_LOCAL) {
    GLOBAL_BASE_URL = 'http://localhost:8080'
}

if (USE_DEV) {
    GLOBAL_BASE_URL = DEV_BASE_URL
}

console.log(GLOBAL_BASE_URL)
export default GLOBAL_BASE_URL
