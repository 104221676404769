import React from 'react'
import FormFieldTitleComponent from './FormFieldTitle'

import axios from 'axios'

import { DndContext, closestCenter, MouseSensor, KeyboardSensor, PointerSensor, useSensor, useSensors, TouchSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, horizontalListSortingStrategy } from '@dnd-kit/sortable'

import SortableScreenshot from '../SortableScreenshot'
import { IMAGE_RESIZER_API } from '../../resources/constants'

interface ScreenShotProps {
    screenshots: string[]
    onChange: (screenshots: string[]) => void
}

const ScreenShotComponent: React.FC<ScreenShotProps> = ({ screenshots, onChange }) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        }),
        useSensor(MouseSensor),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 500,
                tolerance: 5
            }
        })
    )

    function handleDragEnd(event: { active: any; over: any }) {
        const { active, over } = event

        const oldIndex = screenshots.indexOf(active.id)
        const newIndex = screenshots.indexOf(over.id)

        const newscreenshots = arrayMove(screenshots, oldIndex, newIndex)
        onChange(newscreenshots)
    }

    function handleScreenshotDelete(url: string) {
        onChange(screenshots.filter((element) => element !== url))
    }

    async function handleScreenshotFileUpload(files: FileList | null) {
        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file: File = files[i]

                const url = await fileUpload(file)

                const newScreenshots = [...screenshots]
                newScreenshots.push(url)
                onChange(newScreenshots)
            }
        }
    }

    const fileUpload = async (file: File) => {
        const presignedResponse = await axios.get('/v1/upload/presignedURL?ext=png')
        const presignedUrl = presignedResponse.data.url
        const fileName = presignedResponse.data.fileName

        const uploadResponse = await axios.put(presignedUrl, file, {
            headers: {
                'Content-Type': file.type,
                'X-Amz-Acl': 'public-read'
            }
        })
        return IMAGE_RESIZER_API + fileName
    }

    return (
        <div>
            <FormFieldTitleComponent title="Screenshots" subTitle="Add or Remove screenshots below" />
            {screenshots !== undefined && (
                <div className="flex flex-row">
                    <div
                        onClick={() => {}}
                        className="p-2 w-full overflow-hidden flex-col space-y-4 border border-dashed border-colorBorder"
                        style={{ overflowX: 'auto' }} // Enable horizontal scrolling
                    >
                        <div className="w-[100px] md:w-[500px] max-w-full">
                            {''}
                            {/* Adjust the width as needed */}
                            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                <div className="">
                                    <SortableContext items={screenshots} strategy={horizontalListSortingStrategy}>
                                        <div className="flex-row flex items-center">
                                            {screenshots.map((url, index) => (
                                                <div key={url} className="relative">
                                                    {' '}
                                                    {/* Adjust the width as needed */}
                                                    <SortableScreenshot key={url} id={url} url={url} handleDeletePressed={handleScreenshotDelete} />
                                                </div>
                                            ))}
                                        </div>
                                    </SortableContext>
                                </div>
                            </DndContext>
                        </div>

                        <input
                            type="file"
                            accept="image/*"
                            id="fileInput"
                            className="inset-0 opacity-0 cursor-pointer"
                            onChange={(e) => handleScreenshotFileUpload(e.target.files)}
                        />
                    </div>
                    <div>
                        <button
                            className="h-full min-w-[80px] bg-brandOrange"
                            onClick={() => {
                                const fileInput = document.getElementById('fileInput')
                                if (fileInput) {
                                    fileInput.click()
                                }
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 100 100">
                                <rect x="40" y="10" width="20" height="80" fill="white" />
                                <rect x="10" y="40" width="80" height="20" fill="white" />
                            </svg>
                        </button>
                    </div>
                </div>
            )}

            {screenshots === undefined && (
                <div
                    onClick={() => {
                        const fileInput = document.getElementById('fileInput')
                        if (fileInput) {
                            fileInput.click()
                        }
                    }}
                    className="hover:cursor-pointer rounded-sm bg-base-100 aspect-square w-64 overflow-scroll flex-col border border-dashed border-colorBorder text-center justify-center"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full rounded-sm overflow-hidden">
                        <line x1="50%" y1="20%" x2="50%" y2="80%" stroke="white" strokeWidth="10%" />
                        <line x1="20%" y1="50%" x2="80%" y2="50%" stroke="white" strokeWidth="10%" />
                    </svg>
                    <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        className="inset-0 opacity-0 cursor-pointer"
                        onChange={(e) => handleScreenshotFileUpload(e.target.files)}
                    />
                </div>
            )}
        </div>
    )
}
export default ScreenShotComponent
