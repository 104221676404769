import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'

import ScrollToHashElement from './components/scrolltohash/ScrollToHashElement'

import ScrollToTop from './components/scrollToTop/scrollToTop'

import { AppDataContext } from './utility/AppContext'

import { cookieConsentText } from './resources/strings'
import AppSizzleRoutes from './Routes'

const RootComponent = () => {
    // const location = useLocation()
    // const subLocation = {
    //     pathname: '/',
    //     state: location.state,
    //     hash: location.hash,
    //     key: location.key
    // }
    // const previousLocation = location.state?.previousLocation;
    return (
        <AppDataContext>
            <Router className="">
                <ScrollToHashElement />
                <ScrollToTop />
                <CookieConsent
                    enableDeclineButton
                    declineButtonText="I Decline"
                    location="bottom"
                    buttonText="I Agree"
                    cookieName="cookieConsent"
                    style={{ background: '#2B373B', zIndex: 25 }}
                    buttonStyle={{ background: '#fe8528', color: '#ffffff' }}
                    expires={14}
                >
                    {cookieConsentText}
                </CookieConsent>
                <AppSizzleRoutes />
            </Router>
        </AppDataContext>
    )
}

export default RootComponent
