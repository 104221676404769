import React, { useEffect, useState } from 'react'

interface ScreenSizeIndicatorProps {
    className?: string // Accept className prop
}

const ScreenSizeIndicator: React.FC<ScreenSizeIndicatorProps> = ({ className }) => {
    const [screenSize, setScreenSize] = useState<string>('')

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth

            if (screenWidth >= 640) {
                setScreenSize('sm') // Tailwind CSS 'sm' screen size
            }
            if (screenWidth >= 768) {
                setScreenSize('md') // Tailwind CSS 'md' screen size
            }
            if (screenWidth >= 1024) {
                setScreenSize('lg') // Tailwind CSS 'lg' screen size
            }
            if (screenWidth >= 1280) {
                setScreenSize('xl') // Tailwind CSS 'xl' screen size
            }
            if (screenWidth >= 1536) {
                setScreenSize('2xl') // Tailwind CSS '2xl' screen size
            }
        }

        // Initial call to set screen size
        handleResize()

        // Add event listener for window resize
        window.addEventListener('resize', handleResize)

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return <div className={`screen-size-indicator ${className || ''}`}>Screen Size: {screenSize}</div>
}

export default ScreenSizeIndicator
