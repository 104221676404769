import React, { useContext, useEffect, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'

import { ROUTES } from '../../../resources/routes-constants'
import { sendtoMixpanel } from '../../../utility/Mixpanel'
import LoadingButton from '../../../components/buttons/LoadingButton'
import NextButton from '../../../components/buttons/Nextbutton'
import { apiApp } from '../../../services/api/app'
import axios from 'axios'
import { TitleSetContext } from '../../../utility/ModalContext'

type ManualCreateAppDescriptionPageParams = {
    appId: string
    templateId: string | null
}

const ManualCreateAppDescriptionPage: React.FC = () => {
    const { appId, templateId } = useParams<keyof ManualCreateAppDescriptionPageParams>() as ManualCreateAppDescriptionPageParams

    const navigate = useLocation().state.modalFriendlyNavigate
    const [description, setDescription] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const mixpanel = useMixpanel()
    const appDescriptions: string[] = [
        "Get your daily forecast with a twist! WhimsiWeather delivers weather updates through singing animals and talking umbrellas. Rain or shine, it's a symphony of smiles.",
        'Run a wild zoo like no other in ZanyZookeeper! Manage fire-breathing penguins and flying giraffes. Chaos is the name of the game in this extraordinary zoo simulation.',
        "BouncyBurger turns mealtime into a bouncy adventure! Enjoy burgers with edible trampolines and gravity-defying toppings. It's a culinary experience like never before.",
        "Nurture giggling plants in GigglyGardener's enchanted garden. Watch them bloom with joy in this unique gardening game.",
        'Solve wordplay puzzles and riddles that tickle your brain in PunnyPuzzles. Exercise your mind while cracking up with clever challenges.',
        'Soar through the skies with jetpacks and laughter in JollyJetpacks! Experience high-flying adventure with a dose of joy and fun.',
        "Create hilarious tunes with unexpected sound effects in SillySoundboard. Compose a symphony of silliness that'll keep you and your friends laughing.",
        'Challenge your brain with comical teasers and whimsical puzzles in ChuckleChallenge. Laugh your way to solving clever challenges.',
        "Guide funky birds through neon landscapes in FunkyFlock's disco-inspired journey. Groove along and party in the skies with these avian dancers.",
        "Transform your photos into moments of joy with GleeGallery. Add quirky filters, animations, and stickers for a gallery that's all about smiles."

        // ... continued descriptions
    ]

    const setModalTitle = useContext(TitleSetContext)
    useEffect(() => {
        if (setModalTitle != null) {
            setModalTitle('Briefly describe what your app does')
        } else {
            console.log('Set modal title null')
        }
    }, [])

    useEffect(() => {
        sendtoMixpanel(mixpanel, 'page_load', ROUTES.MANUALLY_CREATE_APP_DESCRIPTION.name)
    }, [])

    return (
        <div className="justify-center items-center flex flex-col space-y-2 bg-base-100 m-4 p-16 rounded-sm">
            <h1>{'Now, enter in a description for your app.'}</h1>
            <p>{'(You can change this at any time.)'}</p>

            <div className="">
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-96 relative py-3 px-2 bg-base-200 rounded-sm text-sm md:text-lg outline-none focus:ring-1 focus:ring-brandOrange"
                />
            </div>
            <div className="w-full flex justify-around">
                <button
                    onClick={() => {
                        setDescription(appDescriptions[Math.floor(Math.random() * appDescriptions.length)])
                        // setName(playfulAppNames[Math.floor(Math.random() * playfulAppNames.length)])
                    }}
                >
                    Generate Description For Me
                </button>

                <NextButton
                    isLoading={isLoading}
                    text="Next"
                    onClick={async () => {
                        setIsLoading(true)
                        await axios.put('/v1/app/draft/edit/' + appId, {
                            appData: {
                                description: description
                            }
                        })
                        setIsLoading(false)
                        navigate(ROUTES.MANUALLY_CREATE_APP_ASSET_QUESTION.route(appId, templateId))
                    }}
                />
            </div>
            {/* <div className="justify-self-start">
                <LoadingButton
                    isLoading={isLoading}
                    text="Next"
                    onClick={async () => {
                        setIsLoading(true)
                        await axios.put('/v1/app/draft/edit/' + id, {
                            appData: {
                                description: description
                            }
                        })
                        setIsLoading(false)
                        navigate(ROUTES.MANUALLY_CREATE_APP_ASSET_QUESTION.route(id))
                    }}
                />
            </div> */}
        </div>
    )
}

export default ManualCreateAppDescriptionPage
