import React, { ChangeEvent, useContext } from 'react'

import { FormValueSetContext, FormValuesContext } from '../../components/Form/FormContext'
import FormValues from '../../components/Form/FormValue'
import UpdateButton from '../../components/buttons/UpdateButton'
import Template from '../../types/templates'
import FormLayout from '../../types/formLayout/formLayout'
import FormLayoutColor from '../../types/formLayout/formLayoutColor'
interface ColorInputTextProps {
    displayName: string | null
    color: string | null
    textLayer: string
}

const ColorInputText: React.FC<ColorInputTextProps> = ({ displayName, color, textLayer }) => {
    const formInfoContext = useContext(FormValuesContext)
    const formValues = useContext(FormValuesContext)
    const setFormValues = useContext(FormValueSetContext)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormValues(name, value)
    }
    return (
        <div className="flex flex-col justify-center">
            {displayName != null && <p>{displayName}</p>}
            <div className="flex flex-row rounded-xl overflow-hidden">
                {color != null && color != '' && (
                    <input
                        type="color"
                        name={color}
                        value={formValues[color] as string}
                        onChange={handleChange}
                        style={{ backgroundColor: formValues[color] as string, outline: 'none' }}
                        className="select max-w-xs rounded-sm lg:w-[56px] lg:h-[56px] h-[48px] w-[48px] border-colorBorder border hover:cursor-pointer"
                    />
                )}
                <input
                    type="text"
                    name={textLayer}
                    className="w-96 lg:min-h-[56px] min-h-[33px] srelative py-3 px-2 bg-base-200 text-[16px]  outline-none focus:ring-1 focus:ring-brandOrange"
                    placeholder="Type here"
                    value={(formValues[textLayer] as string) || ''}
                    onChange={handleChange}
                    required
                />
            </div>
        </div>
    )
}

export default ColorInputText
