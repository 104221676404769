export interface AppQueryItemInterface {
    artworkUrl512: string
    trackName: string
    artistName: string
    trackViewUrl: string
}

export class AppQueryItem {
    artworkUrl512: string
    trackName: string
    artistName: string
    trackViewUrl: string

    constructor(data: AppQueryItemInterface) {
        this.artworkUrl512 = data.artworkUrl512
        this.artistName = data.artistName
        this.trackName = data.trackName
        this.trackViewUrl = data.trackViewUrl
    }

    getAppID(): string {
        // Regular expression pattern to match the ID part
        const idPattern = /\/id(\d+)\?/

        // Use the match() method to find the ID using the pattern
        const idMatch = this.trackViewUrl.match(idPattern)

        if (idMatch && idMatch[1]) {
            const extractedId = idMatch[1]
            return 'id' + extractedId
        }
        return ''
    }
}
