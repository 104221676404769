import React, { useContext, useState } from 'react'

import EditIOSAppPage from '../EditIOSAppPage'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import axios from 'axios'

type EditAppParams = {
    appId: string
    templateId: string | null
}

const GettingStartedEditIOSAppPage: React.FC = () => {
    const navigate = useNavigate()
    const { appId, templateId } = useParams<keyof EditAppParams>() as EditAppParams

    const handleRender = async () => {
        const form = await axios.post('/v1/createForm', {
            appID: appId,
            templateID: templateId
        })

        const formDataID = form.data.formDraft.id
        navigate('/video_create/preview/' + formDataID)
    }

    return (
        <div>
            <EditIOSAppPage />
        </div>
    )
}

export default GettingStartedEditIOSAppPage
