import React from 'react'

interface BuyButtonProps {
    text: string
    bgColor: string
    onClick: () => void
}
const BuyButton: React.FC<BuyButtonProps> = ({ text, bgColor, onClick }) => {
    return (
        <div className="">
            <button
                onClick={onClick}
                className={`text-black font-semibold rounded-xl py-2 px-4 bg-${bgColor}  hover:border-transparent transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0`}
            >
                {text}
            </button>
        </div>
    )
}
export default BuyButton
