import axios from 'axios'
import { handleResponse, handleError } from './response'

import GLOBAL_BASE_URL from '../../../utility/BaseURL'
// const dotenv = require('dotenv');

// // get all environment variables here.
// dotenv.config({ path: '.env' });

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = GLOBAL_BASE_URL + '/v1'

const getAll = (resource: string, headers?: object) => {
    const axiosConfig = {}
    if (headers !== null && headers !== undefined) {
        axiosConfig['headers'] = headers
    }
    return axios.get(`${BASE_URL}/${resource}`, axiosConfig).then(handleResponse).catch(handleError)
}

const getSingle = (resource: string, id: string, headers?: object) => {
    if (headers !== null && headers !== undefined) {
        console.log(headers['user_id'])
        const axiosConfig = {
            headers: headers
        }
        return axios.get(`${BASE_URL}/${resource}/${id}`, axiosConfig).then(handleResponse).catch(handleError)
    } else {
        return axios.get(`${BASE_URL}/${resource}/${id}`).then(handleResponse).catch(handleError)
    }
}

const post = (resource: string, model: any, headers?: object) => {
    const axiosConfig = {}
    if (headers !== null && headers !== undefined) {
        axiosConfig['headers'] = headers
    }
    return axios.post(`${BASE_URL}/${resource}`, model, axiosConfig).then(handleResponse).catch(handleError)
}

const put = (resource: string, model: any) => {
    return axios.put(`${BASE_URL}/${resource}`, model).then(handleResponse).catch(handleError)
}

const deleteSingle = (resource: string, id: string) => {
    return axios.delete(`${BASE_URL}/${resource}/${id}`).then(handleResponse).catch(handleError)
}

export const apiProvider = {
    getAll,
    getSingle,
    post,
    put,
    deleteSingle
}
