import React, { useContext, useState } from 'react'
import LoadingButton from '../../components/buttons/LoadingButton'

import { LoginProvider, RegisterProvider } from '../../utility/AppContext'
import { useNavigate } from 'react-router-dom'
import { RequestClose } from '../../utility/ModalContext'
import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isRegistering, setIsRegistering] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [loginFailedText, setLoginFailedText] = useState('')

    const login = useContext(LoginProvider)
    const register = useContext(RegisterProvider)
    const navigate = useNavigate()
    const requestClose = useContext(RequestClose)
    return (
        <div className="">
            <ModalCompatibleTitle title={isRegistering ? 'Register' : 'Login'} />
            <form
                onSubmit={async (e) => {
                    e.preventDefault() // Prevent the default form submission
                    setIsLoading(true)

                    let loginSuccess = false
                    if (isRegistering) {
                        loginSuccess = await register(email, password)
                    } else {
                        loginSuccess = await login(email, password)
                    }
                    if (loginSuccess) {
                        setIsLoading(false)
                        requestClose()
                    } else {
                        // Handle the case where login or registration failed
                        setIsLoading(false)
                        console.error('Login or registration failed.')
                        setLoginFailedText('invalid username/password combination')
                        // Optionally, show an error message to the user
                    }
                }}
            >
                <div className="flex flex-col gap-5 p-5">
                    <input
                        type="text"
                        placeholder="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} // Update the state on user input
                        className="w-full relative py-3 px-2 bg-base-100 rounded-xl text-sm md:text-lg outline-none focus:ring-1 focus:ring-brandOrange"
                    />
                    <input
                        type="password"
                        placeholder="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} // Update the state on user input
                        className="w-full relative py-3 px-2 bg-base-100 rounded-xl text-sm md:text-lg  outline-none focus:ring-1 focus:ring-brandOrange"
                    />

                    <LoadingButton
                        type="submit"
                        isLoading={isLoading}
                        text={isRegistering ? 'Register' : 'Login'}
                        onClick={() => {}}
                        // onClick={async () => {
                        //     setIsLoading(true)
                        //     if (isRegistering) {
                        //         await register(email, password)
                        //     } else {
                        //         await login(email, password)
                        //     }

                        //     setIsLoading(false)
                        //     requestClose()
                        // }}
                    />
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            setIsRegistering(!isRegistering)
                        }}
                    >
                        {isRegistering ? 'Already have an account? Login' : "Don't have an account? Register"}
                    </button>

                    <h1 className="text-center">{loginFailedText}</h1>
                </div>
            </form>
        </div>
    )
}

export default LoginPage
