import React, { useEffect } from 'react'
import MyApps from '../components/my_apps/MyApps'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'

const MyAppsPage: React.FC = () => {
    const featuredRef = React.createRef<HTMLDivElement>()
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <div>
            <div className="flex-col flex space-y-16">
                <div className="flex justify-center items-center mb-10 pt-16" ref={featuredRef}>
                    <h1 className="text-center text-6xl">My Apps</h1>
                </div>
                <div className="flex flex-col justify-center items-center mb-10 px-20 pb-20" ref={featuredRef}>
                    <MyApps
                        onAppClick={(appID) => {
                            navigate('/apps/' + appID)
                        }}
                        onAddClicked={() => {
                            navigate(location.pathname + '/modal' + ROUTES.GET_STARTED_APP_IMPORT.route(null))
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default MyAppsPage
