import React from 'react'

interface StartButtonProps {
    text: string
    onClick: () => void
}
const StartButton: React.FC<StartButtonProps> = ({ text, onClick }) => {
    return (
        <div className="">
            <button
                onClick={onClick}
                className="drop-shadow-lg text-xl xl:text-3xl 2xl:text-4xl text-base-100 font-medium rounded-lg pt-2 pb-[4px] px-4 xl:px-8 bg-brandOrange  hover:border-transparent transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0"
            >
                {text}
            </button>
        </div>
    )
}
export default StartButton
