import React, { createContext, useState, ReactNode, useEffect } from 'react'
import FormValues from './FormValue'
import UploadValues from './UploadValue'
import Layer from '../../types/layer'

const FormValuesContext = createContext<FormValues>({})
const FormValueSetContext = createContext<(formID: string, value: string | null) => void>(() => {})
const FormValueFileContext = createContext<UploadValues>({})

// A "provider" is used to encapsulate only the
// components that needs the state in this context
interface FormDataProviderProps {
    templateID: string
    layers: Layer[]
    defaultValues: FormValues
    children: ReactNode
}

const FormDataProvider: React.FC<FormDataProviderProps> = ({ templateID, layers, defaultValues, children }) => {
    const [formData, setFormData] = useState<FormValues>(defaultValues)

    const setData = (formID: string, value: string | null) => {
        setFormData((currentState) => {
            console.log(currentState)
            const newState = { ...currentState }
            newState[formID] = value
            console.log(newState)
            return newState
        })
    }

    // We need to inject any file uploads here. We do not include it directly in state since
    // file uploads have a more complciated data structure.
    const newFormData = { ...formData }
    for (const [key, value] of Object.entries(newFormData)) {
        console.log(key + ' ' + value)
    }
    console.log('before')

    return (
        <FormValuesContext.Provider value={newFormData}>
            <FormValueSetContext.Provider value={setData}>{children}</FormValueSetContext.Provider>
        </FormValuesContext.Provider>
    )
}

export { FormDataProvider, FormValuesContext, FormValueSetContext }
