import React, { useEffect, useState } from 'react'
import MyApps from '../components/my_apps/MyApps'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'
import axios from 'axios'
import FormDraft from '../types/form'
import NewCard from '../components/card/NewCard'
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu'

interface SelectedFormValues {
    [key: string]: string | null
}
function isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

const DraftPage: React.FC = () => {
    const featuredRef = React.createRef<HTMLDivElement>()
    const navigate = useNavigate()
    const [drafts, setDrafts] = useState<FormDraft[]>([])
    const [isEditing, setIsEditing] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState<string[]>([])

    useEffect(() => {
        const getDrafts = async () => {
            const drafts = (await axios.get('/v1/forms')).data
            setDrafts(drafts.forms)
        }

        getDrafts()
    }, [])

    return (
        <div>
            <div className="flex justify-end pr-5 pt-5">
                <button
                    onClick={() => {
                        setSelectedTemplate([])
                        setIsEditing(!isEditing)
                    }}
                >
                    {isEditing ? 'Done' : 'Edit'}
                </button>
            </div>
            <div className="flex justify-center items-center mb-10 pt-4" ref={featuredRef}>
                <h1 className="font-raj text-center text-6xl">Drafts</h1>
            </div>
            <div className="flex flex-col justify-center items-center mb-10 p-5" ref={featuredRef}>
                <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2 md:gap-6 lg:gap-12">
                    {drafts.map((form, index) => {
                        return (
                            <div key={index} className="relative">
                                <NewCard
                                    header={null}
                                    name={form.template?.name ?? 'No Name'}
                                    id={form.id}
                                    previewImage=""
                                    previewVideo={form.job.finishedURL ?? ''}
                                    cta="Edit"
                                    autoplayOnScroll={isTouchDevice()}
                                    onClick={() => {
                                        if (isEditing) {
                                            let editingTemplates = [...selectedTemplate]
                                            if (editingTemplates.includes(form.id)) {
                                                editingTemplates = editingTemplates.filter((item) => form.id)
                                            } else {
                                                editingTemplates = [...editingTemplates, form.id]
                                            }
                                            setSelectedTemplate(editingTemplates)
                                        } else {
                                            navigate(ROUTES.VIDEO_CREATE_VIEW_PREVIEW.route(form.id))
                                        }
                                    }}
                                />
                                {isEditing && (
                                    <button
                                        className="absolute top-2 right-2 "
                                        onClick={() => {
                                            let editingTemplates = [...selectedTemplate]
                                            if (editingTemplates.includes(form.id)) {
                                                editingTemplates = editingTemplates.filter((item) => item != form.id)
                                            } else {
                                                editingTemplates = [...editingTemplates, form.id]
                                            }
                                            setSelectedTemplate(editingTemplates)
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100">
                                            {selectedTemplate.includes(form.id) && <circle cx="50" cy="50" r="45" fill="red" />}
                                            <circle cx="50" cy="50" r="45" fill="none" stroke="white" strokeWidth="5" />
                                        </svg>
                                    </button>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
            {isEditing && (
                <div className={'fixed bottom-0 w-full p-4 bg-base-100'}>
                    <div className="container mx-auto flex justify-end gap-6">
                        <button
                            onClick={async () => {
                                const drafts = (
                                    await axios.delete('/v1/forms', {
                                        data: {
                                            draftIds: selectedTemplate
                                        }
                                    })
                                ).data
                                setDrafts(drafts.forms)
                                setIsEditing(false)
                            }}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DraftPage
