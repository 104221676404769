import React, { useContext, useEffect, useState } from 'react'

import { Route, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'
import { ROUTES } from '../../../resources/routes-constants'
import { sendtoMixpanel } from '../../../utility/Mixpanel'
import axios from 'axios'
import { AppDataProvider } from '../../../utility/AppContext'
import { TitleSetContext } from '../../../utility/ModalContext'

interface ManualCreateAppAssetQuestion {
    appId: string
    templateId: string
}

const ManualCreateAppAssetQuestion: React.FC = () => {
    const navigate = useLocation().state.modalFriendlyNavigate

    const mixpanel = useMixpanel()
    const { appId, templateId } = useParams<keyof ManualCreateAppAssetQuestion>() as ManualCreateAppAssetQuestion

    const setModalTitle = useContext(TitleSetContext)
    useEffect(() => {
        if (setModalTitle != null) {
            setModalTitle('Do you have assets?')
        } else {
            console.log('Set modal title null')
        }
    }, [])
    useEffect(() => {
        sendtoMixpanel(mixpanel, 'page_load', ROUTES.MANUALLY_CREATE_APP_ASSET_QUESTION.name)
    }, [])
    const appContext = useContext(AppDataProvider)

    return (
        <div className="min-h-full bg-">
            <div className="flex flex-row justify-center bg-base-100 m-5 p-5 rounded-sm">
                <div className="flex flex-col w-3/4 gap-4">
                    <h1>Do you already have an app icon and screenshots?</h1>
                    <div
                        className="bg-base-200 cursor-pointer rounded-2xl border border-colorBorder transition-all duration-300 hover:border-brandOrange basis-1 mb-5 lg:basis-1/2 p-4"
                        onClick={() => {
                            navigate(ROUTES.MANUALLY_CREATE_APP_ASSETS.route(appId, templateId))
                        }}
                    >
                        <p className="select-none">Yes</p>
                    </div>
                    <div
                        className="bg-base-200 cursor-pointer rounded-2xl border border-colorBorder transition-all duration-300 hover:border-brandOrange basis-1 mb-5 lg:basis-1/2 p-4"
                        onClick={async () => {
                            await axios.put('/v1/app/draft/edit/' + appId, {
                                appData: {
                                    iconURL:
                                        'https://dev-appsizzle-user-assets.s3.amazonaws.com/static_assets/prefilled-form-assets/icons/36cc94a5-0212-4048-87df-19069bf21fd5927acf30-37bc-4c47-9a87-2cd4187ac29d725bb0ab-ea14-4484-8501-04113a71a4fb5fe301e5-2f68-467a-b74e-de426efeb1ff.png',
                                    iPhone14Screenshots: [
                                        'https://api.image-resizer.linkedlabs.io/v1/assets/79bd8741-efb8-424e-b4b6-ab9899e8a46abdb516b7-35f0-4d51-a868-326dddfe2e2a17ae3034-4ac0-4289-9c0b-22d30626a408c25d2413-94b2-407d-89e7-3854e8db145f.png',
                                        'https://api.image-resizer.linkedlabs.io/v1/assets/4b0b3e87-7b58-43e3-934e-a6a3eef06a3fdbb57698-758d-48a9-b5e3-e352759bf3df075300a6-46b1-4098-95c4-b28e8ce86d7ef927964b-bf42-474d-97c2-427305295a03.png',
                                        'https://api.image-resizer.linkedlabs.io/v1/assets/a680d439-349a-403e-9b65-30c46ab69a5b08d8d7a5-1288-40ef-a23d-994fb637d6b0ec545850-2574-4109-ad2d-7f8cc6d4f88d5db76d21-7393-4e8c-a65e-8a0d8c660db2.png',
                                        'https://api.image-resizer.linkedlabs.io/v1/assets/4cb6e361-aeb5-4b05-afc1-de1e024eecb9dfa66f48-7680-4a0b-bc04-c29496b570dab11af598-d507-4c94-a521-1ec0afd7215f4b148c7b-4c7b-45a2-8a37-65808bbdd20f.png'
                                    ]
                                }
                            })
                            await axios.post('/v1/app/publish/' + appId)
                            await appContext(true)
                            navigate(ROUTES.GET_STARTED_APP_EDIT.route(appId, templateId))
                        }}
                    >
                        <p className="select-none">No</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManualCreateAppAssetQuestion
