export const minWidthSmall = 640
export const minWidthMedium = 768
export const minWidthLarge = 1024

const stripe_publish_dev_key = 'pk_test_51NXt9RFglnSqR572ZYNGxcgi6i9ov3meSKiPTVs8zaxzw1fbEoIQuHcWLHT31tP5Wksl5rr0B2ElEUqLkpCR7yDY00E5LseXub'
const stripe_publish_prod_key = 'pk_live_51NXt9RFglnSqR572H4YNsWVcKcmrkfKdeiooUWMhxQiOOP61S8poDKwI6bI0u38Dbo8Hpst6LJ03rcICKaHRjH1V00eRtOTlMO'

export const STRIPE_KEY = process.env.REACT_APP_USE_DEV ? stripe_publish_dev_key : stripe_publish_prod_key

const image_resizer_api_dev = 'https://api.image-resizer.linkedlabs.io/v1/dev/assets/'
const image_resizer_api_prod = 'https://api.image-resizer.linkedlabs.io/v1/assets/'
export const IMAGE_RESIZER_API = process.env.REACT_APP_USE_DEV ? image_resizer_api_dev : image_resizer_api_prod
