import React, { useState, useEffect } from 'react'
import Lead from '../../types/lead'
import { apiLead, GetLeadsResponse } from '../../services/api/lead'
import { useNavigate } from 'react-router-dom'

const LeadsPage: React.FC = () => {
    const [leads, setLeads] = useState<Lead[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const newLeads: GetLeadsResponse = await apiLead.getAll()
        console.log(newLeads.leads)
        setLeads(newLeads.leads)
    }

    const handleOnClick = (id) => {
        navigate('/lead/' + id)
    }

    const determineBg = (lead: Lead) => {
        if (
            lead.preview_url !== null &&
            lead.preview_url !== '' &&
            lead.preview_url !== undefined &&
            lead.form_id !== null &&
            lead.form_id !== '' &&
            lead.form_id !== undefined
        ) {
            return 'bg-green-200'
        } else {
            return 'bg-red-400'
        }
    }

    return (
        <div className="space-y-4 text-slate-800 bg-slate-300">
            <table className="table-fixed">
                <thead>
                    <tr>
                        <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-lg leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                            Name
                        </th>
                        <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-lg leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                            Category
                        </th>
                        <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-lg leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                            Website
                        </th>
                        <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-lg leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                            App Store Link
                        </th>
                        <th className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-lg leading-4 font-semibold text-gray-500 uppercase tracking-wider">
                            Released
                        </th>
                    </tr>
                </thead>
                {leads.map((lead, index) => (
                    <tr key={index} className={`border-t  ${determineBg(lead)} `}>
                        <td
                            onClick={() => {
                                handleOnClick(lead.id)
                            }}
                            className="px-6 py-4 whitespace-no-wrap hover:cursor-pointer"
                        >
                            <h4>{lead.name}</h4>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap">
                            <h4>{lead.category}</h4>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap">
                            <h4> {lead.developer_website}</h4>{' '}
                        </td>

                        <td className="px-6 py-4 whitespace-no-wrap">
                            <h4> {lead.appstore_link}</h4>{' '}
                        </td>

                        {/* {lead.contact_emails.map((email, index) => (
                            <tr key={index}>
                                <td className="px-6 py-4 whitespace-no-wrap">
                                    <h4>{email}</h4>
                                </td>
                            </tr>
                        ))} */}
                    </tr>
                ))}
            </table>
        </div>
    )
}

export default LeadsPage
