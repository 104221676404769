import React, { useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'

import StartButton from '../buttons/StartButton'
import HeroVideos from './heroVideo'

interface FeatureCardProps {
    text: string
    color: string
}

const ThreeDText: React.FC<FeatureCardProps> = ({ text, color }) => {
    const commonAttributes = 'text-hero-title-sm lg:text-hero-title'

    return (
        <div>
            <div className="relative inline-block font-bold leading-none tracking-tight">
                {/*
                     pr-[1px] here is to account for the fact that the text behind is pushed to the right 1px. Without this the text would not wrap together. 
                    */}
                <h1 className={'mb-4 lg:mb-0 pr-[1px] ' + color + ' ' + commonAttributes}>{text}</h1>
                <h1 style={{ WebkitTextStroke: '2px white' }} className={'absolute opacity-30 top-[5px] left-[1px] text-transparent ' + commonAttributes}>
                    {text}
                </h1>
            </div>
        </div>
    )
}
export default ThreeDText
