import React from 'react'
import Loading from '../Loading'

interface NextButtonProps {
    text: string
    isLoading: boolean
    onClick: () => void
}
const NextButton: React.FC<NextButtonProps> = ({ text, isLoading, onClick }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className="h-fit text-base-100 bg-brandOrange hover:cursor-pointer focus:outline-none focus:ring-4 font-medium rounded-sm text-lg px-5 py-2 text-center mr-2 mb-2"
        >
            <div className="flex flex-auto justify-center text-center">
                <div className={isLoading ? '' : 'w-0'}>
                    <Loading visible={isLoading} />
                </div>
                <span className="self-center">{isLoading ? '' : text}</span>
            </div>
        </button>
    )
}
export default NextButton
