import { ApiCore } from './utilities/core'
import { SizzleApp } from '../../types/sizzleApp'

const url = 'app'
const plural = 'apps'
const single = 'app'

// plural and single may be used for message logic if needed in the ApiCore class.

export const apiApp = new ApiCore({
    getAll: true,
    getSingle: true,
    post: true,
    deleteSingle: true,
    put: true,
    url: url,
    plural: plural,
    single: single
})

export interface GetAppsResponse {
    apps: SizzleApp[]
}

export interface DeleteAppRequest {
    id: string
}

export interface DeleteAppResponse {
    success: boolean
}
