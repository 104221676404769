import React, { useContext, useEffect, useRef, useState } from 'react'

import { Route, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'

import { ROUTES } from '../../resources/routes-constants'
import { sendtoMixpanel } from '../../utility/Mixpanel'
import axios from 'axios'
import { SizzleApp } from '../../types/sizzleApp'
import { InModal, TitleSetContext } from '../../utility/ModalContext'
import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'
import Lottie from 'lottie-web'
type WhatStageParams = {
    appId: string
    templateId: string | null
}

const WhatStagePage: React.FC = () => {
    const navigate = useLocation().state.modalFriendlyNavigate
    const mixpanel = useMixpanel()
    const { templateId } = useParams<keyof WhatStageParams>() as WhatStageParams
    const inModal = useContext(InModal)
    const setTitle = useContext(TitleSetContext)

    const container = useRef<HTMLDivElement>(null)
    useEffect(() => {
        setTitle('Welcome to AppSizzle')
        if (container.current == null) {
            return
        }
        Lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../components/hero/Main.json')
        })
    }, [])
    useEffect(() => {
        sendtoMixpanel(mixpanel, 'page_load', ROUTES.GET_STARTED.name)
    }, [])

    return (
        <div className="min-h-full bg-">
            <div className="flex flex-col text-center items-center">
                <div className="w-1/3" ref={container}>
                    {' '}
                </div>
                <div className="container pb-10">
                    <h1>{"Let's find your app so we can autogenerate your video!"}</h1>
                </div>
                <div className="flex flex-col justify-center w-1/2 self-center">
                    <button
                        type="button"
                        onClick={() => {
                            navigate(ROUTES.GET_STARTED_APP_IMPORT.route(templateId))
                        }}
                        className="h-fit text-base-100 bg-brandOrange hover:cursor-pointer focus:outline-none font-medium rounded-sm text-lg px-5 py-2 text-center mb-2"
                    >
                        <div className="flex flex-auto justify-center text-center">
                            <span className="self-center">{'Get Started'}</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default WhatStagePage
