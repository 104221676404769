import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import Loading from '../components/Loading'
import CheckoutForm from '../components/checkoutForm/CheckoutForm'
import { useLocation } from 'react-router-dom'
import { STRIPE_KEY } from '../resources/constants'

import FormDraft from '../types/form'
import { sendtoMixpanel } from '../utility/Mixpanel'
import { useMixpanel } from 'react-mixpanel-browser'

const stripePromise = loadStripe(STRIPE_KEY)

const Checkout: React.FC = () => {
    const [form, setForm] = useState<FormDraft | null>(null) // Replace 'any' with the expected data type
    const location = useLocation()
    const mixpanel = useMixpanel()
    const keys = location.pathname.split('/')
    const formId = keys[keys.length - 1]

    useEffect(() => {
        sendtoMixpanel(mixpanel, 'page_load', 'Checkout')
        const startForm = async () => {
            const response = await axios.post('/v1/checkout/start', {
                formId: formId
            })
            setForm(response.data)
        }
        startForm()
    }, [])

    if (form?.paymentInfo.clientSecret == null) {
        return <Loading visible={true} />
    }

    return (
        <div>
            <Elements
                stripe={stripePromise}
                options={{
                    // passing the client secret obtained in step 3
                    clientSecret: form.paymentInfo.clientSecret,
                    appearance: {
                        theme: 'night',
                        variables: {
                            borderRadius: '8px',
                            colorPrimary: '#fe8528'
                        }
                    }
                    // Fully customizable with appearance API.
                }}
            >
                <CheckoutForm formId={form.id} initialPrice={form.template.price} />
            </Elements>
        </div>
    )
}

export default Checkout
