import React from 'react'

interface AppIconProps {
    iconUrl: string
    handleIconDelete: (url) => void
    handleIconFileUpload: (file) => void
}
const AppIcon: React.FC<AppIconProps> = ({ iconUrl, handleIconDelete, handleIconFileUpload }) => {
    if (iconUrl === '' || iconUrl == null) {
        return (
            <div>
                <div
                    onClick={() => {
                        const fileInput = document.getElementById('fileInput')
                        if (fileInput) {
                            fileInput.click()
                        }
                    }}
                    className="relative border-brandOrange border-dashed border-2 hover:cursor-pointer w-[81px] h-[81px] lg:w-[220px] lg:h-[220px]"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full rounded-sm overflow-hidden">
                        <line x1="50%" y1="20%" x2="50%" y2="80%" stroke="white" strokeWidth="10%" />
                        <line x1="20%" y1="50%" x2="80%" y2="50%" stroke="white" strokeWidth="10%" />
                    </svg>
                    <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        className="inset-0 opacity-0 cursor-pointer"
                        onClick={() => {
                            console.log('icon upload clicked')
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleIconFileUpload(e.target.files)}
                    />
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="relative">
                <img className="rounded-xl overflow-hidden w-[81px] h-[81px] lg:w-[138px] lg:h-[138px]" src={iconUrl} />
                <button
                    onClick={handleIconDelete}
                    className="text-xs text-white w-1 h-1 absolute top-0 right-0 flex items-center justify-center p-3 bg-brandOrange rounded-full hover:bg-orange-600 transition duration-300"
                >
                    X
                </button>
            </div>
        </div>
    )
}
export default AppIcon
