import React, { useEffect } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'

import FeaturedTemplates from '../components/featured_templates/FeaturedTemplates'
import { sendtoMixpanel } from '../utility/Mixpanel'

import { ROUTES } from '../resources/routes-constants'
import axios from 'axios'

type EditTemplates = {
    id: string
}

const ShowTemplatesPage: React.FC = () => {
    const { id } = useParams<keyof EditTemplates>() as EditTemplates

    const featuredRef = React.createRef<HTMLDivElement>()
    const navigate = useNavigate()
    const mixpanel = useMixpanel()
    const location = useLocation()

    useEffect(() => {
        sendtoMixpanel(mixpanel, 'page_load', ROUTES.SHOW_TEMPLATES_ROUTE.name)
    }, [])
    return (
        <div>
            <div className="">
                <div className="flex justify-center items-center mb-10 pt-16" ref={featuredRef}>
                    <h1 className="font-raj text-center text-6xl">Templates</h1>
                </div>
                <div className="flex flex-col justify-center items-center mb-10" ref={featuredRef}>
                    <FeaturedTemplates
                        onTemplateSelect={async (templateID) => {
                            if (id != null && id != '') {
                                const form = await axios.post('/v1/createForm', {
                                    appID: id,
                                    templateID: templateID
                                })

                                const formDataID = form.data.formDraft.id
                                navigate('/video_create/preview/' + formDataID)
                            } else {
                                if (localStorage.getItem('last_known_app_count') == '0') {
                                    navigate(location.pathname + '/modal/get_started/' + templateID)
                                } else {
                                    navigate(location.pathname + '/modal/video_create/' + templateID)
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ShowTemplatesPage
