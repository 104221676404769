import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
    const { pathname, state } = useLocation()

    useEffect(() => {
        if (pathname.includes('/modal/') || state?.closedModal == true) {
            return
        }
        window.scrollTo(0, 0)
    }, [pathname])

    return null
}
