import React, { useContext, useEffect, useState } from 'react'
import Template from '../../types/templates'
import axios from 'axios'
import NewCard from '../card/NewCard'
import { TemplateDataProvider } from '../../utility/AppContext'

interface FeaturedTemplatesProps {
    onTemplateSelect: (id: string) => void
}

function isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

const FeaturedTemplates: React.FC<FeaturedTemplatesProps> = ({ onTemplateSelect }) => {
    const [templates, setTemplates] = useState<Template[] | null>(null) // Replace 'any' with the expected data type
    const templateProvider = useContext(TemplateDataProvider)
    useEffect(() => {
        // Function to make the network request
        const fetchData = async () => {
            try {
                const response = await templateProvider(false)
                setTemplates(response)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData() // Call the function to fetch data on page load
    }, [])
    return templates == null ? (
        <h1>Loading...</h1>
    ) : (
        <div className="justify-center">
            <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2 md:gap-6 lg:gap-12">
                {templates.map((item) => (
                    <div key={item.id} className="p-12 md:p-4">
                        <NewCard
                            header={null}
                            id={item.id}
                            name={item.name}
                            previewImage={item.previewImage}
                            previewVideo={item.previewVideo}
                            cta="Try Now"
                            autoplayOnScroll={isTouchDevice()}
                            onClick={onTemplateSelect}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default FeaturedTemplates
