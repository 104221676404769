import React from 'react'
import FormFieldTitleComponent from './FormFieldTitle'

interface DominantColorsProps {
    colors: string[]
    onChange: (colors: string[]) => void
}

const DominantColorsComponent: React.FC<DominantColorsProps> = ({ colors, onChange }) => {
    const handleColorChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedColors = [...colors] // Copy the array
        updatedColors[index] = e.target.value // Update the specific color at index
        onChange(updatedColors) // Update the state
    }

    return (
        <div className="flex-col flex">
            <div className="">
                <FormFieldTitleComponent title="App Colors" subTitle="Edit your app colors below" />
            </div>
            <div className="space-x-4 w-full  h-[86px] lg:w-[225px] lg:h-[139px] rounded-[22px] justify-center items-center bg-textInputBg flex">
                {colors.map((colorHex, index) => (
                    <div key={index}>
                        <input
                            type="color"
                            key={index}
                            defaultValue={colorHex}
                            value={colors[index]}
                            onChange={(e) => handleColorChange(index, e)}
                            style={{ backgroundColor: colors[index], outline: 'none' }}
                            className="rounded-xl w-[50px] h-[50px] lg:h-[66px] border-colorBorder border hover:cursor-pointer"
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
export default DominantColorsComponent
