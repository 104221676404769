import React, { useEffect, useRef, useState } from 'react'

import FeaturedTemplates from '../components/featured_templates/FeaturedTemplates'

import Modal from '../components/modal/Modal'
import AppStoreSearch from './GettingStartedFlow/AppStoreSearch'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'
const Playground: React.FC = () => {
    const featuredRef = React.createRef<HTMLDivElement>()
    const location = useLocation()

    const handleScroll = () => {
        if (featuredRef.current != null) {
            featuredRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }
    return (
        <div className="flex flex-col gap-5 p-10">
            <button
                className="bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                onClick={() => {
                    navigate(location.pathname + '/modal' + ROUTES.LOGIN.route())
                }}
            >
                Login
            </button>
            <button
                className="bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                onClick={() => {
                    localStorage.clear()
                    window.location.replace('/')
                }}
            >
                Clear Local Storage
            </button>

            <Modal
                title="Playground"
                isOpen={isOpen}
                onRequestClose={closeModal}
                size={{ height: '80vh', width: '80%' }}
                sizeInfo="h-screen w-full md:h-80v md:w-9/12"
            >
                <div>
                    <AppStoreSearch onSelection={(value) => {}} />
                </div>
            </Modal>
        </div>
    )
}

export default Playground
