import React, { useEffect } from 'react'
import Hero from '../components/hero/Hero'

import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'
import BgTest from '../components/features/BgTest'
import LoadingButton from '../components/buttons/LoadingButton'
import { sendtoMixpanel } from '../utility/Mixpanel'
import { useMixpanel } from 'react-mixpanel-browser'

const HomePage: React.FC = () => {
    const navigate = useNavigate()
    const mixpanel = useMixpanel()

    useEffect(() => {
        sendtoMixpanel(mixpanel, 'page_load', 'Home')
    }, [])

    return (
        <div className="bg-base-200 flex flex-col space-y-16">
            <div className="">
                <div className="">
                    <div className="absolute overflow-hidden w-sreen h-screen opacity-30">
                        <img src="/images/bgtexture.png" alt="Background Pattern" className="w-[2303px] h-[1449px] -left-[253px] top-0 object-cover" />
                    </div>
                </div>
                <Hero />
                <BgTest />
            </div>

            <div className="px-5 lg:px-0">
                <div className="md:m-24 md:px-[5%] p-8 flex flex-col space-y-5 lg:space-y-0 lg:flex-row lg:space-x-8 2xl:space-x-12 rounded-vLarge bg-tertBackground">
                    <div className="w-full">
                        <video className="rounded-mLarge" muted autoPlay loop playsInline src="/videos/appsHomeOutput.mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="w-full flex-col space-y-4 text-center lg:text-left">
                        <h1 className="text-4xl md:text-5xl font-bold">Elevate Your App</h1>
                        <p className="text-sm md:text-lg">
                            {
                                'Explore our wide array of templates, crafted to cater to the requirements of every app. Whether your app is designed to track flight prices or inventively tally your push-ups, our videos are the perfect way to showcase your creation. Dive into our template collection today and unlock the potential of your app.'
                            }
                        </p>
                        <LoadingButton
                            isLoading={false}
                            text={'Explore Templates'}
                            onClick={() => {
                                navigate(ROUTES.SHOW_TEMPLATES_ROUTE.route)
                            }}
                        />
                    </div>
                </div>
            </div>

            <div></div>
        </div>
    )
}

export default HomePage
