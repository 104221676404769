import React, { useRef, useState } from 'react'

const HeroVideos: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0)

    const videoURLS = ['/videos/pushfitpro.mp4', '/videos/triviaVaultHero.mp4']
    const videoRefs = useRef<HTMLVideoElement[]>([])

    const toggleVideo = () => {
        console.log('Toggle video')
        videoRefs.current[currentIndex].pause()
        videoRefs.current[currentIndex].currentTime = 0

        let nextIndex = currentIndex + 1
        if (currentIndex + 1 >= videoURLS.length) {
            nextIndex = 0
        }
        videoRefs.current[nextIndex].play()
        videoRefs.current[nextIndex].currentTime = 0
        setCurrentIndex(nextIndex)
    }

    return (
        <div className="hidden lg:inline h-full overflow-hidden rounded-3xl p-2 transform -rotate-0  transition-transform hover:scale-110">
            <div className=" w-full h-3/4 overflow-hidden rounded-3xl drop-shadow-xl shadow-xl ">
                {videoURLS.map((video, index) => {
                    return (
                        <video
                            autoPlay
                            key={video}
                            muted
                            playsInline
                            onEnded={toggleVideo}
                            ref={(ref) => {
                                if (ref != null) {
                                    videoRefs.current[index] = ref
                                }
                            }}
                            className={'object-cover h-full w-full ' + (index == currentIndex ? 'inline' : 'hidden')}
                        >
                            <source src={video} key={video} type="video/mp4" />
                        </video>
                    )
                })}
            </div>
        </div>
    )
}
export default HeroVideos
