import React, { useContext, useEffect, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'

import { ROUTES } from '../../../resources/routes-constants'
import { sendtoMixpanel } from '../../../utility/Mixpanel'
import LoadingButton from '../../../components/buttons/LoadingButton'
import NextButton from '../../../components/buttons/Nextbutton'
import { apiApp } from '../../../services/api/app'
import axios from 'axios'
import { TitleSetContext } from '../../../utility/ModalContext'

type ManualCreateAppNamePageParams = {
    appId: string
    templateId: string
}

const ManualCreateAppNamePage: React.FC = () => {
    const { appId, templateId } = useParams<keyof ManualCreateAppNamePageParams>() as ManualCreateAppNamePageParams

    const navigate = useLocation().state.modalFriendlyNavigate
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const mixpanel = useMixpanel()
    const playfulAppNames: string[] = [
        'WhimsyWidgets',
        'FunFusion',
        'GiggleGram',
        'QuirkQuest',
        'ZanyZest',
        'JoyJotter',
        'ChucklChat',
        'BounceBack',
        'SnickerSync',
        'PlayPal',
        'WittyWave',
        'GigglyGizmo',
        'JollyJungle',
        'FrolicFrame',
        'LaughLine',
        'CheeryCheck',
        'PunnyPulse',
        'MirthMate',
        'SmileSync',
        'ChuckleChic',
        'BlissBurst',
        'FunkyFlicks',
        'WhimsiWatch',
        'ChuckleChannel',
        'PlayfulPixel',
        'QuirkyQuest',
        'GleeGlide',
        'GiddyGlobe',
        'ZestZip',
        'JoyRide',
        'GiggleGrid',
        'ChuckleCraft',
        'WittyWhirl',
        'SparkSplash',
        'FunFrame',
        'QuirkQuotient',
        'JollyJolt',
        'SnickerSphere',
        'PlayPause',
        'LaughLink',
        'BounceBox',
        'FrolicFiesta',
        'ChuckleCraze',
        'MirthMingle',
        'WhimsyWall',
        'GigglyGlobe',
        'ZanyZone',
        'SmileStream',
        'JoyJive',
        'ChuckleChirp',
        'PunnyPlay',
        'GleeGather',
        'PlayfulPulse',
        'QuirkQube',
        'JollyJump',
        'BounceBliss',
        'SnickerStream',
        'WittyWhirl',
        'FrolicFlux',
        'ChuckleCircuit',
        'MirthMix',
        'WhimsyWave',
        'GiddyGlimpse',
        'ZestZap',
        'GiggleGlide',
        'QuirkyQuota',
        'JoyJam',
        'ChuckleChat',
        'PunnyPatch',
        'FunFloat',
        'GleeGallery',
        'PlayfulPlanet',
        'JollyJot',
        'SnickerSync',
        'WhimsiWeave',
        'BounceBlend',
        'QuirkQuick',
        'FrolicFlock',
        'MirthMagnet',
        'ChuckleCove',
        'ZanyZen',
        'GiddyGlobe',
        'JoyJive',
        'PunnyPlayground',
        'WittyWhim',
        'SnickerSphere',
        'QuirkQuest',
        'GleeGlow',
        'FrolicFrame',
        'FunFactor',
        'ChuckleCraze',
        'WhimsyWander',
        'MirthMingle',
        'JoyJotter',
        'GiddyGaze',
        'QuirkyQuirk',
        'BounceBliss',
        'SnickerSnap',
        'ChuckleCircuit',
        'ZestZip'
    ]
    const setModalTitle = useContext(TitleSetContext)
    useEffect(() => {
        if (setModalTitle != null) {
            setModalTitle('What will you call your app?')
        } else {
            console.log('Set modal title null')
        }
    }, [])

    useEffect(() => {
        sendtoMixpanel(mixpanel, 'page_load', ROUTES.MANUALLY_CREATE_APP_NAME.name)
    }, [])

    return (
        <div className="justify-center items-center flex flex-col space-y-2 bg-base-100 m-4 p-16 rounded-sm">
            <h1>{"What's your app name?"}</h1>
            <p>{'(You can change this at any time.)'}</p>

            <div className="py-2">
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)} // Update the state on user input
                    className="w-96 relative py-3 px-5 bg-base-200 rounded-sm text-sm md:text-lg  outline-none focus:ring-1 focus:ring-brandOrange ring-1 ring-colorBorder"
                />
            </div>
            <div className="w-full flex justify-around">
                <button
                    onClick={() => {
                        setName(playfulAppNames[Math.floor(Math.random() * playfulAppNames.length)])
                    }}
                >
                    Generate Name For Me
                </button>
                <NextButton
                    isLoading={isLoading}
                    text="NEXT"
                    onClick={async () => {
                        setIsLoading(true)
                        await axios.put('/v1/app/draft/edit/' + appId, {
                            appData: {
                                name: name
                            }
                        })
                        setIsLoading(false)
                        navigate(ROUTES.MANUALLY_CREATE_APP_DESCRIPTION.route(appId, templateId))
                    }}
                />
            </div>
        </div>
    )
}

export default ManualCreateAppNamePage
