import React, { useContext, useEffect, useState } from 'react'
import MyApps from '../../components/my_apps/MyApps'
import Loading from '../../components/Loading'
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import { TitleSetContext } from '../../utility/ModalContext'
import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'
interface VideoCreateParams {
    templateId: string
}
const VideoCreateSelectApp: React.FC = () => {
    const { templateId } = useParams<keyof VideoCreateParams>() as VideoCreateParams
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const location = useLocation()
    const handleAppSelection = async (id: string) => {
        const form = await axios.post('/v1/createForm', {
            appID: id,
            templateID: templateId
        })

        const formDataID = form.data.formDraft.id
        navigate('/video_create/preview/' + formDataID)
    }

    return (
        <div className="px-20 pb-20 flex-col items-center">
            <div className="">
                <div className="pt-10" />
                <ModalCompatibleTitle title={'Select app'} />
                {loading ? (
                    <Loading visible />
                ) : (
                    <MyApps
                        onAddClicked={() => {
                            location?.state?.modalFriendlyNavigate(ROUTES.GET_STARTED_APP_IMPORT.route(templateId))
                        }}
                        onAppClick={handleAppSelection}
                    />
                )}
            </div>
        </div>
    )
}

export default VideoCreateSelectApp
