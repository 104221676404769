import React, { useState, useRef, useEffect, ReactNode } from 'react'
import SortableItemComponent from './SortableItem'
import UploadListComponent from './UploadList'

interface DragAndDropProps {
    onDrop: (files: FileList | null) => void
    onDragIn: () => void
    onDragOut: () => void
    children: ReactNode
}

const DragAndDrop: React.FC<DragAndDropProps> = ({ onDrop, onDragIn, onDragOut, children }) => {
    const [drag, setDrag] = useState(false)
    const dragCounterRef = useRef(0)
    const dropRef = useRef<HTMLDivElement>(null)

    const handleDrag = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault()
        e.stopPropagation()
        dragCounterRef.current++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDrag(true)
        }
        onDragIn()
    }

    const handleDragOut = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault()
        e.stopPropagation()
        dragCounterRef.current--
        if (dragCounterRef.current === 0) {
            setDrag(false)
        }
        onDragOut()
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault()
        e.stopPropagation()
        setDrag(false)
        // When we drop the file we are no longer dragging
        onDragOut()
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            onDrop(e.dataTransfer.files)
            e.dataTransfer.clearData()
            dragCounterRef.current = 0
        }
    }

    useEffect(() => {
        const div = dropRef.current
        if (div) {
            div.addEventListener('dragenter', handleDragIn as any)
            div.addEventListener('dragleave', handleDragOut as any)
            div.addEventListener('dragover', handleDrag as any)
            div.addEventListener('drop', handleDrop as any)

            return () => {
                div.removeEventListener('dragenter', handleDragIn as any)
                div.removeEventListener('dragleave', handleDragOut as any)
                div.removeEventListener('dragover', handleDrag as any)
                div.removeEventListener('drop', handleDrop as any)
            }
        }
    }, [handleDragIn, handleDragOut, handleDrag, handleDrop])

    return (
        <div style={{ position: 'relative' }} ref={dropRef}>
            {drag && (
                <div
                    style={{
                        border: 'dashed grey 4px',
                        backgroundColor: 'rgba(255,255,255,.8)',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 9999
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: 0,
                            left: 0,
                            textAlign: 'center',
                            color: 'grey',
                            fontSize: 36
                        }}
                    >
                        <div>drop here :)</div>
                    </div>
                </div>
            )}
            {children}
        </div>
    )
}

export default DragAndDrop
