import React from 'react'

interface CreateVideoButtonProps {
    text: string

    onClick: () => void
}
const CreateVideoButton: React.FC<CreateVideoButtonProps> = ({ text, onClick }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className="h-fit text-base-100 bg-brandOrange hover:cursor-pointer focus:outline-none font-medium rounded-sm text-lg px-5 py-2 text-center mr-2 mb-2"
        >
            <div className="flex flex-auto justify-center text-center">
                <span className="self-center">{text}</span>
            </div>
        </button>
    )
}
export default CreateVideoButton
