import React from 'react'
import FormFieldTitleComponent from './FormFieldTitle'

interface FeatureCardProps {
    value: string
    onChange: (string) => void
}

const AppNameComponent: React.FC<FeatureCardProps> = ({ value, onChange }) => {
    return (
        <div className="">
            <FormFieldTitleComponent title="App Name" subTitle="Edit your app name below" />
            <input
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)} // Update the state on user input
                className="lg:min-h-[56px] min-h-[33px] w-full py-2 px-4 lg:py-4 bg-base-100 rounded-xl text-[16px] md:text-lg  outline-none focus:ring-1 focus:ring-brandOrange"
            />
        </div>
    )
}
export default AppNameComponent
