import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'

import { SizzleApp } from '../../types/sizzleApp'

import { sendtoMixpanel } from '../../utility/Mixpanel'
import { AppDataProvider } from '../../utility/AppContext'

interface MyAppsProps {
    onAppClick: (appID: string) => void
    onAddClicked: () => void
}

const MyApps: React.FC<MyAppsProps> = ({ onAppClick, onAddClicked }) => {
    const [apps, setApps] = useState<SizzleApp[] | null>(null) // Replace 'any' with the expected data type
    const navigate = useNavigate()
    const mixpanel = useMixpanel()
    const appContext = useContext(AppDataProvider)
    useEffect(() => {
        sendtoMixpanel(mixpanel, 'page_load', 'MyApps')

        const fetchData = async () => {
            setApps(await appContext(false))
        }

        fetchData() // Call the function to fetch data on page load
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setApps(await appContext(false))
        }

        fetchData()
    })

    return apps == null ? (
        <h1>Loading...</h1>
    ) : (
        <div className="justify-center">
            <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-10 items-center">
                {apps.map((item) => (
                    <div
                        key={item.id}
                        className="flex flex-row h-full hover:cursor-pointer transition ease-in duration-200 transform hover:-translate-y-2 active:translate-y-0"
                        onClick={() => {
                            onAppClick(item.id)
                        }}
                    >
                        <img src={item.iconURL} width={'520px'} height={'520px'} className="rounded-xl overflow-hidden bg-base-100" />
                    </div>
                ))}
                <div className="bg-base-100 aspect-square rounded-xl transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0">
                    <button
                        className="w-full h-full"
                        onClick={() => {
                            onAddClicked()
                        }}
                    >
                        <div className="w-full h-full">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full rounded-sm overflow-hidden">
                                <line x1="50%" y1="20%" x2="50%" y2="80%" stroke="white" strokeWidth="10%" />
                                <line x1="20%" y1="50%" x2="80%" y2="50%" stroke="white" strokeWidth="10%" />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MyApps
