import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'
import axios from 'axios'

import { ROUTES } from '../resources/routes-constants'
import { SizzleApp, Tagline } from '../types/sizzleApp'

import Modal from '../components/modal/Modal'

import Loading from '../components/Loading'

import DeleteButton from '../components/buttons/DeleteButton'
import CancelButton from '../components/buttons/CancelButton'
import UpdateButton from '../components/buttons/UpdateButton'

import { apiApp, DeleteAppResponse } from '../services/api/app'
import { sendtoMixpanel } from '../utility/Mixpanel'
import { AppDataProvider } from '../utility/AppContext'

import CreateVideoButton from '../components/buttons/CreateVideoButton'
import { InModal } from '../utility/ModalContext'
import AppIcon from '../components/app_icon/AppIcon'
import AppNameComponent from '../components/edit_ios_page/AppNameComponent'
import DominantColorsComponent from '../components/edit_ios_page/DominantColorsComponent'
import DescriptionComponent from '../components/edit_ios_page/DescriptionComponent'
import TagLineComponent from '../components/edit_ios_page/TagLineComponent'
import ScreenShotComponent from '../components/edit_ios_page/ScreenshotComponent'
import { IMAGE_RESIZER_API } from '../resources/constants'

type EditAppParams = {
    appId: string
    templateId: string | null
}

const EditIOSAppPage: React.FC = () => {
    const inModal = useContext(InModal)
    const { appId, templateId } = useParams<keyof EditAppParams>() as EditAppParams
    const id = appId
    const [app, setApp] = useState<SizzleApp | null>(null) // Replace 'any' with the expected data type
    const [name, setName] = useState('')
    const [iconUrl, setIconUrl] = useState('')
    const [description, setDescription] = useState('')
    const [tagLines, setTagLines] = useState<Tagline[]>([])
    const [appUpdateText, setAppUpdateText] = useState('Update')
    const [appUpdateEnabled, setAppUpdateEnabled] = useState(true)
    const [showDeleteAppOption, setShowDeleteAppOption] = useState(false)

    const [dominantColors, setDominantColors] = useState([''])

    const handleRender = async () => {
        const form = await axios.post('/v1/createForm', {
            appID: appId,
            templateID: templateId
        })

        const formDataID = form.data.formDraft.id
        navigate('/video_create/preview/' + formDataID)
    }

    const navigate = useNavigate()
    const mixpanel = useMixpanel()

    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    useEffect(() => {
        _getApp(id)
    }, [])

    // Update name state when app changes
    useEffect(() => {
        if (app) {
            setIconUrl(app.iconURL)
            setName(app.name)
            setDescription(app.description)
            if (app.taglines !== undefined) {
                setTagLines(app.taglines)
            }

            setDominantColors(app.dominantColors)
        }
    }, [app])

    async function _getApp(id: string) {
        const result = await apiApp.getSingle(id)
        setApp(result.app)
    }

    const appCacheRefresh = useContext(AppDataProvider)
    const handleDeleteApp = async (id: string) => {
        const response: DeleteAppResponse = await apiApp.deleteSingle(id)
        appCacheRefresh(true)
        sendtoMixpanel(mixpanel, 'delete_app_request', ROUTES.EDIT_APP.name)
        if (response.success) {
            console.log('app deleted')
            navigate('/apps')
        } else {
            console.log('failed to delete app')
        }
    }

    const handleUpdateApp = async () => {
        if (!appUpdateEnabled) {
            return
        }
        setAppUpdateEnabled(false)
        setAppUpdateText('Updating')
        const newApp = { ...app } as SizzleApp
        newApp.name = name
        newApp.description = description
        newApp.dominantColors = dominantColors
        const response: SizzleApp = (await apiApp.put({ app: newApp })).app
        setApp(response)
        setAppUpdateText('Done')
        setAppUpdateEnabled(true)
        const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
        await wait(2000)
        setAppUpdateText('Update')
    }

    async function handleIconFileUpload(files: FileList | null) {
        if (files && files?.length > 1) {
            return
        }

        if (files && app) {
            const newIconUrl = await fileUpload(files[0])
            const newApp = { ...app }
            newApp.iconURL = newIconUrl
            setApp(newApp)
        }
    }

    const fileUpload = async (file: File) => {
        const presignedResponse = await axios.get('/v1/upload/presignedURL?ext=png')
        const presignedUrl = presignedResponse.data.url
        const fileName = presignedResponse.data.fileName

        const uploadResponse = await axios.put(presignedUrl, file, {
            headers: {
                'Content-Type': file.type,
                'X-Amz-Acl': 'public-read'
            }
        })
        return IMAGE_RESIZER_API + fileName
    }

    function handleCreateVideoClick() {
        if (templateId != null) {
            handleRender()
        } else {
            navigate(ROUTES.SHOW_TEMPLATES_ROUTE_WITH_APP.route(id))
        }
    }

    function handleIconDelete() {
        console.log('icon deleted')
        if (app == null) {
            return
        }
        const newApp = { ...app }

        setIconUrl('')
        newApp.iconURL = ''
        setApp(newApp)
        console.log(newApp.iconURL)
    }

    if (app === null) {
        return <Loading visible={true} />
    }

    const appIcon = () => {
        return (
            <div className="lg:w-[225px] lg:h-[193px] w-[160px] h-[160px] flex flex-col items-center justify-center bg-transparent lg:bg-textInputBg rounded-[22px]">
                <AppIcon iconUrl={app.iconURL} handleIconDelete={handleIconDelete} handleIconFileUpload={handleIconFileUpload} />
            </div>
        )
    }

    const appNameLayout = () => {
        return (
            <div className="flex-col flex pt-[12px] lg:pt-[25px]">
                <AppNameComponent
                    value={name}
                    onChange={(value) => {
                        setName(value)
                    }}
                />
            </div>
        )
    }

    const largeTopLayout = () => {
        return (
            <div className="hidden flex-col lg:flex pl-[90px] pr-[90px] space-y-8">
                <div className="hidden lg:flex pt-[69px] space-x-24">
                    <div className="flex flex-col space-y-10">
                        {/* Left Column */}
                        {appIcon()}

                        <DominantColorsComponent
                            colors={dominantColors}
                            onChange={(colors) => {
                                console.log('COLOR change')
                                console.log(colors)
                                setDominantColors([...colors])
                            }}
                        />
                    </div>
                    <div className="w-full flex flex-col space-y-10">
                        {/* Right Column */}
                        {appNameLayout()}
                        <DescriptionComponent description={description} onChange={setDescription} />
                    </div>
                </div>

                <ScreenShotComponent
                    screenshots={app.iPhone14Screenshots}
                    onChange={(screenshots) => {
                        const newApp = { ...app }
                        newApp.iPhone14Screenshots = screenshots
                        setApp(newApp)
                    }}
                />
                <TagLineComponent tagLines={app.taglines} onChange={setTagLines} />
            </div>
        )
    }
    const smallTopLayout = () => {
        return (
            <div className="flex flex-col lg:hidden px-8 w-full space-y-12">
                <div className="flex items-center space-x-">
                    {appIcon()}
                    <div className="flex-col flex w-full">{appNameLayout()}</div>
                </div>
                <DescriptionComponent description={description} onChange={setDescription} />
                <DominantColorsComponent
                    colors={dominantColors}
                    onChange={(colors) => {
                        console.log('COLOR change')
                        console.log(colors)
                        setDominantColors([...colors])
                    }}
                />
                <ScreenShotComponent
                    screenshots={app.iPhone14Screenshots}
                    onChange={(screenshots) => {
                        const newApp = { ...app }
                        newApp.iPhone14Screenshots = screenshots
                        setApp(newApp)
                    }}
                />
                {/* <ScreenShotComponent
                    screenshots={app.iPhone14Screenshots}
                    onChange={(screenshots) => {
                        const newApp = { ...app }
                        newApp.iPhone14Screenshots = screenshots
                        setApp(newApp)
                    }}
                /> */}
                <TagLineComponent tagLines={app.taglines} onChange={setTagLines} />
            </div>
        )
    }

    return (
        <div>
            <div className="flex flex-col items-center">
                <div className="flex flex-col max-w-[1024px] w-full">
                    {largeTopLayout()}
                    {smallTopLayout()}

                    <div className="flex flex-col pb-24">
                        <div className="flex flex-col items-center">
                            {showDeleteAppOption && <p>Are you sure you want to delete?</p>}
                            <div className="flex items-center space-x-4 px-16 py-8 self-end">
                                <DeleteButton
                                    onClick={() => {
                                        if (!showDeleteAppOption) {
                                            setShowDeleteAppOption(true)
                                        } else {
                                            handleDeleteApp(app.id)
                                        }
                                    }}
                                    text={showDeleteAppOption ? 'Yes, Delete' : 'Delete'}
                                />
                                <UpdateButton
                                    text={showDeleteAppOption ? 'Cancel' : appUpdateText}
                                    onClick={() => {
                                        if (showDeleteAppOption) {
                                            setShowDeleteAppOption(false)
                                            return
                                        }
                                        handleUpdateApp()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                // We have to do really annoying things to make the footer bottom show properly inside modals
            }
            {/* <div className={inModal ? 'md:pb-48' : ''} /> */}
            <div className={'sticky ' + (inModal ? 'bottom-0' : 'bottom-0') + ' md:fixed md:bottom-0 w-full p-4 bg-base-100'}>
                <div className="container mx-auto flex justify-end gap-6">
                    <CreateVideoButton text="Create Video" onClick={handleCreateVideoClick} />
                </div>
            </div>
        </div>
    )
}

export default EditIOSAppPage
