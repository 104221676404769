import React, { useState } from 'react'

import FeatureCard from '../card/FeatureCard'
import MiddleFeatureCard from '../card/MiddleFeatureCard'
const BgTest: React.FC = () => {
    return (
        <div className="relative px-20 py-16">
            {/* Background div */}
            <div className="absolute inset-0 bg-primaryText h-full lg:h-1/2 z-0"></div>
            {/* Content */}
            <div className="relative z-10">
                <h1 className="text-black font-bold text-4xl pb-2.5 text-center">Features</h1>
                <h1 className="text-black text-center pb-10 font-medium text-base">What Makes AppSizzle Sizzle</h1>
                <div className="flex flex-col items-center space-y-8 lg:space-y-0 lg:flex-row lg:flex justify-around lg:space-x-12">
                    <FeatureCard
                        heading="Auto Asset Import"
                        subText="We import assets directly from the app store for even faster video creation."
                        bgColor="base-200"
                        main={false}
                        image="fi_share"
                    />
                    <MiddleFeatureCard
                        heading="Instant Video Creation"
                        subText="See your app store preview video in seconds – no more waiting on contractors!"
                        image="fi_video"
                    />
                    <FeatureCard
                        heading="AI Generation"
                        subText="Our AI crafts the perfect marketing taglines to sell your app."
                        bgColor="base-200"
                        image="fi_slack"
                        main={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default BgTest
