import React, { useState, ChangeEvent, useRef, FormEvent, useContext, Children, ReactNode } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { UniqueIdentifier } from '@dnd-kit/core'

interface SortableScreenshotProps {
    id: UniqueIdentifier
    url: string
    handleDeletePressed: (url) => void
}

const SortableScreenshot: React.FC<SortableScreenshotProps> = ({ id, url, handleDeletePressed }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    const handleXClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // Prevent the click event from propagating to parent elements
        e.stopPropagation()
        handleDeletePressed(url)
    }

    return (
        <div style={style} className="w-32 md:w-48 h-64 md:h-96 p-2" ref={setNodeRef} {...attributes}>
            <img className="border border-colorBorder aspect-auto" style={{ touchAction: 'none' }} src={url} {...listeners} />
            <button
                onClick={handleXClick}
                className="absolute top-1 right-1 flex items-center justify-center p-2 bg-brandOrange rounded-full hover:bg-orange-600 transition duration-300"
                style={{ fontSize: '12px', width: '20px', height: '20px' }}
            >
                X
            </button>
        </div>
    )
}

export default SortableScreenshot
