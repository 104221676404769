import React, { useContext, useEffect, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'

import { ROUTES } from '../../../resources/routes-constants'
import { sendtoMixpanel } from '../../../utility/Mixpanel'
import LoadingButton from '../../../components/buttons/LoadingButton'
import { apiApp } from '../../../services/api/app'
import axios from 'axios'
import { SizzleApp } from '../../../types/sizzleApp'
import SimpleUploadComponent from './SimpleUploadButton'
import Loading from '../../../components/Loading'
import { AppDataProvider } from '../../../utility/AppContext'
import { TitleSetContext } from '../../../utility/ModalContext'

type ManualCreateAppAssetsPageParams = {
    appId: string
    templateId: string | null
}

const ManualCreateAppAssetsPage: React.FC = () => {
    const { appId, templateId } = useParams<keyof ManualCreateAppAssetsPageParams>() as ManualCreateAppAssetsPageParams

    const navigate = useLocation().state.modalFriendlyNavigate
    const [icon, setIcon] = useState<string | null>('')
    const [screenshots, setScreenshots] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const [uploadingScreenshots, setUploadingScreenshots] = useState(false)
    const [uploadingIcons, setUploadingIcons] = useState(false)

    const mixpanel = useMixpanel()
    const appContext = useContext(AppDataProvider)

    const setModalTitle = useContext(TitleSetContext)
    useEffect(() => {
        if (setModalTitle != null) {
            setModalTitle('Upload your assets')
        } else {
            console.log('Set modal title null')
        }
    }, [])

    useEffect(() => {
        sendtoMixpanel(mixpanel, 'page_load', ROUTES.MANUALLY_CREATE_APP_ASSETS.name)
    }, [])

    const fileUpload = async (file: File) => {
        const presignedResponse = await axios.get('/v1/upload/presignedURL?ext=png')
        const presignedUrl = presignedResponse.data.url
        const fileName = presignedResponse.data.fileName

        const uploadResponse = await axios.put(presignedUrl, file, {
            headers: {
                'Content-Type': file.type,
                'X-Amz-Acl': 'public-read'
            }
        })
        return 'https://api.image-resizer.linkedlabs.io/v1/assets/' + fileName
    }

    return (
        <div className="flex flex-col justify-center bg-base-100 m-5 p-5 rounded-sm">
            <div className="flex-col space-y-4">
                <h1>App Icon</h1>
                {uploadingIcons && <Loading visible />}
                {!uploadingIcons && (
                    <SimpleUploadComponent
                        filesSelected={async (files) => {
                            setUploadingIcons(true)
                            const file = files?.item(0)
                            if (file == null) {
                                return
                            }
                            const url = await fileUpload(file)
                            setIcon(url)
                            setUploadingIcons(false)
                        }}
                    />
                )}
                {icon != null && <img src={icon} style={{ width: '250px' }} />}
                <h1>iPhone 14 Screenshots</h1>
                {uploadingScreenshots && <Loading visible />}

                {!uploadingScreenshots && (
                    <SimpleUploadComponent
                        filesSelected={async (files) => {
                            if (files == null) {
                                return
                            }
                            setUploadingScreenshots(true)
                            for (let i = 0; i < files.length; i++) {
                                const file = files?.item(i)
                                if (file == null) {
                                    continue
                                }
                                const url = await fileUpload(file)
                                setScreenshots((screenshotList) => {
                                    return [...screenshotList, url]
                                })
                            }
                            setUploadingScreenshots(false)
                        }}
                    />
                )}
                <div className="flex flex-row gap-2">
                    {screenshots.map((screenshot) => {
                        return <img src={screenshot} key={screenshot} style={{ height: '200px' }} />
                    })}
                </div>
                <LoadingButton
                    isLoading={isLoading}
                    text="Next"
                    onClick={async () => {
                        setIsLoading(true)
                        await axios.put('/v1/app/draft/edit/' + appId, {
                            appData: {
                                iconURL: icon,
                                iPhone14Screenshots: screenshots
                            }
                        })
                        await axios.post('/v1/app/publish/' + appId)
                        await appContext(true)
                        navigate(ROUTES.GET_STARTED_APP_EDIT.route(appId, templateId))
                        setIsLoading(false)
                    }}
                />
            </div>
        </div>
    )
}

export default ManualCreateAppAssetsPage
