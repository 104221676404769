import React, { useState, ChangeEvent, useRef, FormEvent, useContext } from 'react'
import Loading from '../Loading'

import DragAndDrop from './DragDrop'
import UploadListComponent from './UploadList'
import UploadItem from './UploadItem'

interface FileUploadFieldProps {
    uploadItem: UploadItem
    filesSelected: (file: FileList, uploadItem: UploadItem) => void
    onDelete: () => void
}

const FileUploadField: React.FC<FileUploadFieldProps> = ({ uploadItem, filesSelected, onDelete }) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null)

    // NSChris TODO
    const uploadContext = async (file: File | null, uploadItem: UploadItem) => {}
    // const setFormValues = useContext(FormValueSetContext)
    const s3URL = uploadItem.s3URL ?? ''
    const [key, setKey] = useState(Math.random().toString(36))
    const [uploadFailed, setUploadFailed] = useState<boolean>(false)
    const [isDraggedIn, setIsDraggedIn] = useState<boolean>(false)

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target
        if (files != null) {
            filesSelected(files, uploadItem)
        }
    }

    const handleDelete = () => {
        setKey(Math.random().toString(36))
        uploadContext(null, uploadItem)
    }

    const handleClick = () => {
        if (hiddenFileInput.current != null && (s3URL == null || s3URL == '')) {
            hiddenFileInput.current.click()
        }
    }

    return (
        <div>
            <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                type="file"
                onChange={handleFileChange}
                key={key}
                style={{ display: 'none' }}
                ref={hiddenFileInput}
                multiple
            />
            <button className={'w-full'} onClick={handleClick}>
                <div>
                    {s3URL == '' && uploadItem?.isUploading == false && <h1 className="py-3 ">{isDraggedIn ? 'Drop' : 'Upload'}</h1>}
                    {/* <input type="file" onChange={handleFileChange} className="file-input file-input-bordered w-full max-w-xs" key={key} /> */}
                    {uploadItem?.isUploading == true && <div>Uploading...</div>}
                    {uploadItem?.isUploading == true && <Loading visible={true} />}
                    {uploadFailed && <h1 className="text-red-600">Upload Failed Please Try Again</h1>}
                    {s3URL != '' && (
                        <div>
                            <img src={s3URL} alt="Selected Preview" width="200" />
                            <button onClick={handleDelete} className="text-red-600">
                                Delete
                            </button>
                        </div>
                    )}
                </div>
            </button>
        </div>
    )
}

export default FileUploadField
