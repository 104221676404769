import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import RootComponent from './RootComponent'
import { persistor, store } from './store/reducers/store'

import { MixpanelProvider } from 'react-mixpanel-browser'
import axios from 'axios'

import GLOBAL_BASE_URL from './utility/BaseURL'

const MIXPANEL_TOKEN = 'c44ebb32dafca464902662bdf3b3c1fe'

const MIXPANEL_CONFIG = {
    track_pageview: false, // Set to `false` by default
    debug: true
}
axios.defaults.baseURL = GLOBAL_BASE_URL

const App: React.FC = () => {
    return (
        <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <RootComponent />
                </PersistGate>
            </Provider>
        </MixpanelProvider>
    )
}

export default App
