import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Lead from '../../types/lead'
import { apiLead } from '../../services/api/lead'
import axios from 'axios'

type LeadParams = {
    leadId: string
}

const LeadPage: React.FC = () => {
    const { leadId } = useParams<keyof LeadParams>() as LeadParams
    const [lead, setLead] = useState<Lead>()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const newLead: Lead = await apiLead.getSingle(leadId)
        console.log(newLead)
        setLead(newLead)
    }
    console.log(lead?.preview_url)

    const getPreviewFormUrl = () => {
        let url = ''
        if (window.location.hostname.startsWith('localhost')) {
            url = 'http://' + window.location.hostname + ':3000/video_create/preview/' + lead?.form?.id
        }
        return url
    }
    console.log(window.location.hostname + '/preview/' + lead?.form_id)

    // const handleResetClick = async () => {
    //     const headers = {
    //         shadowed_user_id: ''
    //     }

    //     const form = await axios.post(
    //         '/v1/leads/createForm',
    //         {
    //             appID: appId,
    //             templateID: templateId
    //         },
    //         { headers: headers }
    //     )

    //     const formDataID = form.data.formDraft.id

    //     // create form api req
    //     // get form Id
    //     // set form id on leads api req
    // }

    return (
        <div className="text-left space-y-8 flex flex-col text-secondaryText items-center">
            {/* <div className="flex bg-brandOrange p-2 hover:cursor-pointer">
                <p className="text-slate-900"> Reset</p>
            </div> */}
            <div className=" flex flex-row space-x-4">
                <h3>Name:</h3>
                <h3>{lead?.name}</h3>
            </div>
            <div className="text-left flex flex-row space-x-4  items-center">
                <h2>Category:</h2>
                <h3>{lead?.category}</h3>
            </div>

            <div className="flex flex-row space-x-4 text-center items-center">
                <h2>Artist:</h2>
                <h3>{lead?.artist}</h3>
            </div>

            <div className="flex flex-row space-x-4">
                <h3>Emails:</h3>
                {lead?.contact_emails.map((email, index) => <h4 key={index}> {email} </h4>)}
            </div>

            <div className="flex flex-row space-x-4">
                <h3>Appstore Link:</h3>
                <a href={lead?.appstore_link}>
                    <h3 className="hover:cursor-pointer hover:text-brandOrange">{lead?.appstore_link}</h3>
                </a>
            </div>

            <div className="flex flex-row space-x-4">
                <h3>Developer Website:</h3>
                <a href={lead?.developer_website}>
                    <h3 className="hover:cursor-pointer hover:text-brandOrange">{lead?.developer_website}</h3>
                </a>
            </div>

            <div className="flex flex-row space-x-4">
                <h3 className="">Form URL:</h3>
                <a href={getPreviewFormUrl()}>
                    <h3 className="hover:cursor-pointer hover:text-brandOrange">FORM_ID: {getPreviewFormUrl()}</h3>
                </a>
            </div>

            {lead != null && lead.preview_url != null && (
                <div style={{ maxWidth: '300px' }} className="justify-center items-center">
                    <video muted autoPlay controls className="rounded-sm relative h-1/4">
                        <source src={lead?.form?.job.finishedURL} type="video/mp4" />
                        {/* Add additional <source> elements for different video formats (e.g., WebM, Ogg) */}
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
        </div>
    )
}

export default LeadPage
