import React from 'react'
import FormFieldTitleComponent from './FormFieldTitle'
import { Tagline } from '../../types/sizzleApp'

interface DominantColorsProps {
    tagLines: Tagline[]
    onChange: (tagliens: Tagline[]) => void
}

const TagLineComponent: React.FC<DominantColorsProps> = ({ tagLines, onChange }) => {
    const handleFieldChange = (index: number, field: keyof Tagline, value: string) => {
        if (tagLines !== undefined) {
            const updatedTagLines = [...tagLines]

            if (field === 'title') {
                updatedTagLines[index].title = value
            }
            if (field === 'subtitle') {
                updatedTagLines[index].subtitle = value
            }
            onChange(updatedTagLines)
        }
    }

    return (
        <div className="flex-col flex">
            <FormFieldTitleComponent title="Tagline Creation" subTitle="edit your taglines below" />

            {tagLines !== undefined && (
                <div className="flex flex-col space-y-2 bg-textInputBg rounded-xl">
                    {tagLines.map((string, index) => (
                        <div key={index} className="flex-col flex p-4 space-y-2">
                            <h1>{'Tagline ' + (index + 1)}</h1>
                            <input
                                type="text"
                                value={tagLines[index].title}
                                onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
                                className="w-full lg:min-h-[56px] min-h-[33px] py-2 px-4 bg-base-100 rounded-xl text-[16px] md:text-lg outline-none focus:ring-1 focus:ring-brandOrange"
                            />

                            <input
                                type="text"
                                value={string.subtitle}
                                onChange={(e) => handleFieldChange(index, 'subtitle', e.target.value)}
                                className="w-full lg:min-h-[56px] min-h-[33px] py-2 px-4 bg-base-100 rounded-xl text-[16px] md:text-lg   outline-none focus:ring-1 focus:ring-brandOrange"
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
export default TagLineComponent
