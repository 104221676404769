import React, { createContext, useState, ReactNode } from 'react'
import { SizzleApp } from '../types/sizzleApp'

import { AxiosError, AxiosResponse } from 'axios'

import axios from 'axios'

import { apiUser } from '../services/api/user'
import AnonymousUser from '../types/anonymousUser'
import { apiApp } from '../services/api/app'
import Template from '../types/templates'

const getUserID = async () => {
    const savedUserID = localStorage.getItem('user_id')
    const savedSession = localStorage.getItem('session')
    if (savedUserID != null && savedUserID != '') {
        console.log(savedUserID)
        const headers = {
            user_id: savedUserID,
            session: savedSession,
            pre_auth: true
        }
        const user: AnonymousUser | null = await apiUser.getSingle(savedUserID, headers).catch((error: AxiosError) => {
            console.log(error)
            if (error.code === '404') {
                return null
            }

            return null
        })

        // Intentionally fall through to create a new user if we can't grab that one
        // https://linear.app/linkedlabs/issue/LIN-59/better-recovery-when-a-user-fails-to-load
        if (user != null) {
            localStorage.setItem('account_type', user.type ?? '')
            return user
        }
    }

    console.log('creating new user')

    const headers = { pre_auth: true, anonymous: true }

    return await apiUser
        .post({}, headers)
        .then((res: AnonymousUser) => {
            const anonymousUser = res
            localStorage.setItem('user_id', anonymousUser.id)
            localStorage.setItem('session', anonymousUser.session)
            return anonymousUser
        })
        .catch((error) => {
            console.log(error)
            return null
        })
}

let userIDPromise: Promise<AnonymousUser | null> = getUserID()

if (userIDPromise !== null) {
    axios.interceptors.request.use(async (req) => {
        if (req.baseURL == axios.defaults.baseURL && req.headers['pre_auth'] != 'true') {
            const userID = await userIDPromise
            req.headers['user_id'] = userID?.id
            req.headers['session_id'] = userID?.session
        }
        return req
    })
}

interface AddAppContextType {
    (app: SizzleApp): Promise<void> // Asynchronous function signature
}

const AppDataProvider = createContext<(boolean) => Promise<SizzleApp[]>>(() => {
    return new Promise((resolve, reject) => {
        resolve([])
    })
})

const TemplateDataProvider = createContext<(boolean) => Promise<Template[]>>(() => {
    return new Promise((resolve, reject) => {
        resolve([])
    })
})

const RegisterProvider = createContext<(email: string, password: string) => Promise<boolean>>(() => {
    return new Promise((resolve, reject) => {
        resolve(false)
    })
})

const LoginProvider = createContext<(email: string, password: string) => Promise<boolean>>(() => {
    return new Promise((resolve, reject) => {
        resolve(false)
    })
})

const AppSetContext = createContext<AddAppContextType>(async () => {})

interface AppDataContextProps {
    children: ReactNode
}

const AppDataContext: React.FC<AppDataContextProps> = ({ children }) => {
    const getAppsPromise = async () => {
        const apps = (await apiApp.getAll()).apps as SizzleApp[]
        localStorage.setItem('last_known_app_count', '' + apps.length)
        return apps
    }

    const getTemplatesPromise = async () => {
        return (await axios.get('/v1/templates')).data as Template[]
    }

    const [appPromise, setAppsPromise] = useState<Promise<SizzleApp[]>>(() => {
        return getAppsPromise()
    })

    const [templatesPromise, setTemplatesPromise] = useState<Promise<Template[]>>(() => {
        return getTemplatesPromise()
    })

    const getApps = async (hardRefresh: boolean) => {
        const promise = hardRefresh ? getAppsPromise() : appPromise
        if (hardRefresh) {
            setAppsPromise(promise)
        }

        return await promise
    }

    const getTemplates = async (hardRefresh: boolean) => {
        const promise = hardRefresh ? getTemplatesPromise() : templatesPromise
        if (hardRefresh) {
            setTemplatesPromise(promise)
        }

        return await promise
    }

    const login = async (email: string, password: string) => {
        try {
            const result = (
                await axios.post('/v1/login', {
                    email: email,
                    password: password
                })
            ).data as AnonymousUser
            localStorage.setItem('user_id', result.id)
            localStorage.setItem('session', result.session)
            userIDPromise = getUserID()
            await userIDPromise
            await getTemplates(true)
            await getApps(true)
            return true
        } catch (error: any) {
            const err: AxiosError = error
            console.error(err.response?.data)
            // You can handle the error here, e.g., show an error message to the user
            return false // Login failed
        }
    }

    const register = async (email: string, password: string) => {
        const result = (
            await axios.post('/v1/register', {
                email: email,
                password: password
            })
        ).data as AnonymousUser
        localStorage.setItem('user_id', result.id)
        localStorage.setItem('session', result.session)
        userIDPromise = getUserID()
        await userIDPromise
        await getTemplates(true)
        await getApps(true)
        return true
    }

    return (
        <RegisterProvider.Provider value={register}>
            <LoginProvider.Provider value={login}>
                <TemplateDataProvider.Provider value={getTemplates}>
                    <AppDataProvider.Provider value={getApps}>{children}</AppDataProvider.Provider>
                </TemplateDataProvider.Provider>
            </LoginProvider.Provider>
        </RegisterProvider.Provider>
    )
}

export { AppDataContext, AppDataProvider, AppSetContext, TemplateDataProvider, RegisterProvider, LoginProvider }
