import React, { ReactNode } from 'react'
import Template from '../../types/templates'

import VideoPlayer from '../videoplayer/VideoPlayer'
import CTAButton from '../buttons/CTAButton'

interface CardProps {
    id: string
    name: string
    previewImage: string
    previewVideo: string
    cta: string
    header: ReactNode | null
    autoplayOnScroll: boolean
    onClick: (id: string) => void
}

const NewCard: React.FC<CardProps> = ({ id, name, previewImage, previewVideo, header, cta, autoplayOnScroll, onClick }) => {
    return (
        <div
            className="flex flex-col bg-base-100 rounded-sm shadow transition ease-in duration-200 transform hover:-translate-y-1 active:translate-y-0 hover:cursor-pointer"
            onClick={() => onClick(id)}
            // You can adjust this width as needed
        >
            {header != null && header}
            <div className="p-4">
                <VideoPlayer previewImage={previewImage} url={previewVideo ?? ''} autoplayOnScroll={autoplayOnScroll} />
            </div>
            <div className="flex justify-between items-center text-xl p-5">
                <h5 className="font-semibold">{name}</h5>
                <CTAButton text={cta} />
            </div>
        </div>
    )
}
export default NewCard
