// response.js

import { AxiosError, AxiosResponse } from 'axios'

export function handleResponse(response: AxiosResponse) {
    if (response.data) {
        return response.data
    }

    return response
}

export function handleError(error: AxiosError) {
    if (error.response !== null && error.response !== undefined) {
        console.log('ASDSDSD')
        throw error.response.data
    }

    console.log('ASDSDSsddsdsD')
    throw error
}
