import React, { ChangeEvent, useContext } from 'react'

import { FormValueSetContext, FormValuesContext } from '../../components/Form/FormContext'
import FormValues from '../../components/Form/FormValue'
import UpdateButton from '../../components/buttons/UpdateButton'
import Template from '../../types/templates'
import FormLayout from '../../types/formLayout/formLayout'
import FormLayoutColor from '../../types/formLayout/formLayoutColor'
interface MultiColorComponentProps {
    colors: FormLayoutColor[]
}

const MultiColorComponent: React.FC<MultiColorComponentProps> = ({ colors }) => {
    const formInfoContext = useContext(FormValuesContext)
    const formValues = useContext(FormValuesContext)
    const setFormValues = useContext(FormValueSetContext)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormValues(name, value)
    }
    return (
        <div className="flex flex-row space-x-8 justify-center">
            {colors.map((color) => {
                return (
                    <div key={color.layerName}>
                        <p>{color.displayName}</p>
                        <input
                            type="color"
                            name={color.layerName}
                            value={formValues[color.layerName] as string}
                            onChange={handleChange}
                            style={{ backgroundColor: formValues[color.layerName] as string, outline: 'none' }}
                            className="select max-w-xs rounded-sm h-16 w-16 border-colorBorder border hover:cursor-pointer"
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default MultiColorComponent
